import {Divider} from "@chakra-ui/react";

const SecretsVilleDivider = () => {
    const dividerStyles = {
        boxSizing: "border-box",
        opacity: "0.35",
        backgroundColor: "#FFFFFF"
    }
    return (
        <Divider/>
    )
}

export default SecretsVilleDivider;
