import { createIcon } from '@chakra-ui/icons';

export const MySecretsIcon = createIcon({
  displayName: 'MySecretsIcon',
  viewBox: '0 0 24 24',
  path: (
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-36.000000, -516.000000)'
        fill='currentColor'
        fillRule='nonzero'
      >
        <g id='side_menu_trending' transform='translate(0.000000, 67.000000)'>
          <g id='gift-(3)' transform='translate(36.000000, 449.000000)'>
            <path
              d='M4.38117647,6.91764706 L11.0682353,6.91764706 L11.0682353,5.07294118 L12.4517647,5.07294118 L12.4517647,6.91764706 L19.1388235,6.91764706 C19.5208621,6.91764706 19.8305882,6.60792094 19.8305882,6.22588235 L19.8305882,4.38117647 C19.8305882,3.99913788 19.5208621,3.68941176 19.1388235,3.68941176 L15.8628565,3.68941176 C16.0403172,3.34320659 16.1411765,2.95152941 16.1411765,2.53647059 C16.1411765,1.13786071 15.0033158,0 13.6047059,0 C12.8783529,0 12.2228367,0.307374118 11.76,0.798296471 C11.2971633,0.307374118 10.6416471,0 9.91529412,0 C8.51668424,0 7.37882353,1.13786071 7.37882353,2.53647059 C7.37882353,2.95152941 7.47968282,3.34320659 7.65714353,3.68941176 L4.38117647,3.68941176 C3.99913788,3.68941176 3.68941176,3.99913788 3.68941176,4.38117647 L3.68941176,6.22588235 C3.68941176,6.60792094 3.99913788,6.91764706 4.38117647,6.91764706 Z M13.6047059,1.38352941 C14.2404376,1.38352941 14.7576471,1.90073882 14.7576471,2.53647059 C14.7576471,3.17220235 14.2404376,3.68941176 13.6047059,3.68941176 L12.4517647,3.68941176 L12.4517647,2.53647059 C12.4517647,1.90073882 12.9689741,1.38352941 13.6047059,1.38352941 Z M8.76235294,2.53647059 C8.76235294,1.90073882 9.27956235,1.38352941 9.91529412,1.38352941 C10.5510259,1.38352941 11.0682353,1.90073882 11.0682353,2.53647059 L11.0682353,3.68941176 L9.91529412,3.68941176 C9.27956235,3.68941176 8.76235294,3.17220235 8.76235294,2.53647059 Z'
              id='Shape'
            ></path>
            <path
              d='M23.0868169,14.2740113 C22.4619228,13.57104 21.3873816,13.5026014 20.6783689,14.1205779 L17.9212715,16.5237224 C17.9224706,16.5589101 17.9239464,16.5940518 17.9239464,16.6295162 C17.9239464,18.3455539 16.5278729,19.7416734 14.8117892,19.7416734 L11.1039765,19.7416734 C10.7219379,19.7416734 10.4122118,19.4319473 10.4122118,19.0499087 C10.4122118,18.6678701 10.7219379,18.358144 11.1039765,18.358144 L12.9591049,18.358144 L14.8123887,18.358144 C15.7667934,18.358144 16.5405092,17.5844282 16.5405092,16.6300235 C16.5405092,15.6752038 15.7661478,14.9013496 14.811328,14.9019031 L11.2008235,14.9041628 C10.2139981,13.7819821 8.85034541,13.102208 7.35986918,12.9899576 C5.86639529,12.8775689 4.41364329,13.3472772 3.26886494,14.3127962 L2.94493459,14.5859972 L6.34611106,22.5221064 L15.1298165,22.5221064 C15.9842381,22.5221064 16.8049478,22.2077224 17.4406334,21.6368781 L22.9508621,16.6892386 C23.6530494,16.0588104 23.7138325,14.9793807 23.0868169,14.2740113 Z'
              id='Path'
            ></path>
            <path
              d='M12.4517647,13.5198955 L14.8104056,13.5184198 C15.9545384,13.5184198 16.9558447,14.1375953 17.4969892,15.0582419 L18.9082353,13.8281459 L18.9082353,8.30117647 L12.4517647,8.30117647 L12.4517647,13.5198955 Z'
              id='Path'
            ></path>
            <path
              d='M6.92562541,11.5901026 C7.10405459,11.5901026 7.28506635,11.596928 7.46363388,11.6103482 C8.78218353,11.7096856 10.021088,12.1664809 11.0682353,12.9243784 L11.0682353,8.30117647 L4.61176471,8.30117647 L4.61176471,11.9826099 C5.35130729,11.7251351 6.13332424,11.5901026 6.92562541,11.5901026 Z'
              id='Path'
            ></path>
            <path
              d='M0.419255529,15.8279915 C0.250649412,15.9002579 0.1176,16.0365355 0.0494381176,16.2068941 C-0.0187237647,16.3772066 -0.0163717647,16.5676725 0.0558945882,16.7362786 L2.82295341,23.1927492 C2.93538824,23.4550664 3.19074165,23.6121892 3.45910024,23.6121892 C3.54999812,23.6121892 3.64241788,23.5941572 3.73128659,23.556064 L5.04301082,22.993936 L1.73102588,15.2658635 L0.419255529,15.8279915 Z'
              id='Path'
            ></path>
          </g>
        </g>
      </g>
    </g>
  )
});
