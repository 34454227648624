import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import { Box, Text } from '@chakra-ui/react';
import { makeStyles, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const labelStyles = {
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: '300',
  letterSpacing: '0.4px',
  lineHeight: '15px',
  marginBottom: '-10px'
};
const autoCompleteStyles = {
  textAlign: 'start',
  color: '#000000',
  fontFamily: 'lato',
  fontSize: '12px',
  fontWeight: '300',
  letterSpacing: '0.4',
  lineHeight: '15px'
};
const inputStyles = {
  fontFamily: 'Lato',
  fontSize: '12px',
  borderRadius: '16px'
};
const useStyles = makeStyles({
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  popper: {
    zIndex: '10000000'
  }
});
const AutoComplete = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const classes = useStyles();
  // const loading = open && props.options.length === 0;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    props.getOptions(search);
  }, [props.getOptions, search, open]);

  useEffect(() => {
    setLoading(false);
  }, [props.options]);

  useEffect(() => {
    if (!open) {
      props.getOptions('', true);
    }
  }, [open]);
  const defaultProps = {
    options: props.options,
    getOptionLabel: (option) => option.name
  };
  let outerLabel;
  if (props.outerLabel) {
    outerLabel = <Text css={labelStyles}>{props.outerLabel}</Text>;
  }
  return (
    <Box css={autoCompleteStyles}>
      {outerLabel}
      <MaterialAutocomplete
        {...defaultProps}
        id='auto-complete'
        autoComplete
        includeInputInList
        loading={loading}
        style={{ width: props.width ? props.width : '225px' }}
        filterOptions={(x) => x}
        multiple
        autoHighlight
        disableClearable
        classes={{ ...classes }}
        onOpen={() => {
          setLoading(true);
          setSearch('');
          setOpen(true);
        }}
        onClose={() => {
          setLoading(false);
          setOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          setLoading(true);
          setSearch(newInputValue);
          // props.getOptions(newInputValue)
        }}
        value={props.selectedOptions}
        size='small'
        getOptionSelected={(option, value) => option.id === value.id}
        filterSelectedOptions
        onChange={(event, newValue) => {
          props.setSelectedOptions(newValue);
          // setOptions(newValue ? [newValue, ...options] : options);
          // setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            margin='normal'
            variant='outlined'
            css={inputStyles}
            classes={{ root: classes.input }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              style: { borderRadius: '16px' }
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: { input: classes.option },
              style: { fontSize: '12px', fontFamily: 'Lato' }
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutoComplete;
