import {Box, Flex, HStack, Text} from "@chakra-ui/react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {useTranslation} from "react-i18next";
import {SECRET_STATUS_FILTERS, SHARE_PRICES} from "../../../utils/Constants";
import AutoComplete from "../../../components/AutoComplete";
import {useCallback, useContext, useEffect, useState} from "react";
import {secretsApi, usersApi} from "../../../api";
import {makeStyles, Slider} from "@material-ui/core";
import {FiltersContext} from "../contexts";

const containerStyles = {
    paddingLeft: "28px"
}

const optionStyles = {
    boxSizing: "border-box",
    border: "0.8px solid #D8D8D8",
    borderRadius: "15.2px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontFamily: "lato",
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.4px",
    lineHeight: "15px",
    padding: "5px",
    minWidth: "40px",
    // flex: "1 1 auto",
    margin: "2px",
    "&:hover": {
        cursor: "pointer"
    }
}

const selectedOptionStyles = {
    boxSizing: "border-box",
    border: "0.8px solid #802081",
    borderRadius: "15.2px",
    backgroundColor: "#FFFFFF",
    color: "#802081",
    fontFamily: "lato",
    fontSize: "12px",
    fontWeight: "bold",
    letterSpacing: "0.4px",
    lineHeight: "15px",
    padding: "5px",
    minWidth: "40px",
    marginRight: "2px",
    marginLeft: "2px",
    "&:hover": {
        cursor: "pointer"
    }
}

const headerStyles = {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontFamily: "lato",
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.4px",
    lineHeight: "15px",
    textAlign: "start",
    marginBottom: "8px"
}

const sellerStylesContainer = {
    marginTop: "13px"
}

const partnerStylesContainer = {
    marginTop: "4px"
}

const secretStatusStyles = {
    marginTop: "12px"
}

const percentageStyles = {
    opacity: "0.5",
    color: "#000000",
    fontFamily: "lato",
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.4px",
    lineHeight: "15px"
}

const sharesSoldStyles = {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontFamily: "lato",
    fontSize: "14px",
    fontWeight: "300",
    letterSpacing: "0.47px",
    lineHeight: "17px",
    textAlign: "start",
}

const valuetext = (value) => {
    return `${value}%`;
}

const dateInputStyles = makeStyles({
    input: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: "300",
        lineHeight: "15px",
        letterSpacing: "0.4px",
        color: "#000000",
    },
    root: {
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: "300",
        lineHeight: "15px",
        letterSpacing: "0.4px",
        color: "#000000",
        border: "1px solid #D8D8D8",
        borderRadius: "16px",
        paddingLeft: "20px"
    }
});

const sliderStyles = makeStyles({
    root: {
        color: "#802081",
    },
    // thumb: {
    //     height: "24px",
    //     width: "24px"
    // }
});

const SideMenuFilters = (props) => {
    const {state, dispatch} = useContext(FiltersContext);
    const percentage = [state.shares_sold_from, state.shares_sold_to]
    const classes = dateInputStyles();
    const sliderClasses = sliderStyles();
    const {t} = useTranslation();
    const [partners, setPartners] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [subCategories, setSubCategories] = useState([])
    const handleDateChange = (date) => {
        dispatch({type: 'date', payload: date});
    };
    const getSellers = useCallback((search = '', setEmpty = false) => {
        if (setEmpty) {
            setSellers(() => []);
            return;
        }
        (async () => {
            const newSellers = await usersApi.get('users/?is_seller=true&name=' + search);
            setSellers(() => [...newSellers.data.items]);
        })();
    }, []);

    const getPartners = useCallback((search = '', setEmpty = false) => {
        if (setEmpty) {
            setPartners(() => []);
            return;
        }
        (async () => {
            try {
                const link = search ? 'v1/partners/?name=' + search : 'v1/partners'
                const newPartners = await secretsApi.get(link);
                setPartners(() => [...newPartners.data.items]);
            } catch (error) {
                setPartners([]);
            }
        })();
    }, []);

    useEffect(async () => {
        if (props.categoryId) {
            let newSubCategories = await secretsApi.get(`v1/categories/${props.categoryId}/sub_categories`);
            newSubCategories = newSubCategories.data.items;
            setSubCategories(newSubCategories);
        }
    }, [props.categoryId]);

    const handleChooseSubCategory = (subCategory) => {
        const subCategoryIndex = state.sub_categories_ids.findIndex(element => element === subCategory.id);
        let tmp = [...state.sub_categories_ids];
        if (subCategoryIndex !== -1) {
            tmp.splice(subCategoryIndex, 1);
            tmp = [...tmp];
        } else {
            tmp = [...state.sub_categories_ids, subCategory.id];
        }
        dispatch({type: 'subCategories', payload: tmp})
    }

    const handleChooseStatus = (status) => {
        dispatch({type: 'status', payload: status})
    }

    const handleChooseSharePrice = (price) => {
        const shareIndex = state.shares_prices.findIndex(element => element === price);
        let tmp = [...state.shares_prices];
        if (shareIndex !== -1) {
            tmp.splice(shareIndex, 1);
        } else {
            tmp = [...state.shares_prices, price];
        }
        dispatch({type: 'prices', payload: tmp})
    }

    return (
        <Box css={containerStyles}>
            <Flex alignItems="stretch" flexWrap='wrap'>
                {subCategories.map(subCategory => {
                    let subCategoryComponent;
                    if (state.sub_categories_ids.includes(subCategory.id)) {
                        subCategoryComponent = <Box key={subCategory.id} css={selectedOptionStyles}
                                                    onClick={() => handleChooseSubCategory(subCategory)}>
                            {subCategory.name}
                        </Box>
                    } else {
                        subCategoryComponent = <Box key={subCategory.id} css={optionStyles}
                                                    onClick={() => handleChooseSubCategory(subCategory)}>
                            {subCategory.name}
                        </Box>
                    }
                    return subCategoryComponent;
                })}
            </Flex>
            <Box css={secretStatusStyles}>
                <Text css={headerStyles}>{t('DASHBOARD.SECRET_STATUS')}</Text>
                <HStack spacing="4px">
                    {SECRET_STATUS_FILTERS.map(status => {
                            let statusComponent;
                            if (state.status === status) {
                                statusComponent = <Box key={status} css={selectedOptionStyles}
                                                       onClick={() => handleChooseStatus(status)}>
                                    {t(`STATUS.${status}`)}
                                </Box>
                            } else {
                                statusComponent = <Box key={status} css={optionStyles}
                                                       onClick={() => handleChooseStatus(status)}>
                                    {t(`STATUS.${status}`)}
                                </Box>
                            }
                            return statusComponent;
                        }
                    )}
                </HStack>
            </Box>
            <Box css={sellerStylesContainer}>
                <AutoComplete outerLabel={t('DASHBOARD.SELLER')} label={t('DASHBOARD.SEARCH_NAMES')}
                              options={sellers} getOptions={getSellers} selectedOptions={state.sellers_ids}
                              setSelectedOptions={(sellers) => {
                                  dispatch({type: 'sellers', payload: sellers})
                              }}/>

            </Box>
            <Box css={partnerStylesContainer}>
                <AutoComplete outerLabel={t('DASHBOARD.PARTNER')} label={t('DASHBOARD.SEARCH_NAMES')}
                              options={partners} getOptions={getPartners} selectedOptions={state.partners_ids}
                              setSelectedOptions={(partners) => {
                                  dispatch({type: 'partners', payload: partners})
                              }}/>

            </Box>
            <Box mt="-4px" textAlign="start" w="134px">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        style={{width: "134px"}}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        InputProps={{classes: {...classes}}}
                        InputAdornmentProps={{position: "end", variant: 'outlined'}}
                        InputLabelProps={{classes: {input: classes.root}}}
                        id="date-picker-inline"
                        label={t('DASHBOARD.END_DATE')}
                        value={state.end_date}
                        onChange={handleDateChange}
                        emptyLabel={t('DASHBOARD.DEFINE')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Box>
            <Box mt="12px">
                <HStack>
                    <Text css={sharesSoldStyles}>{t('DASHBOARD.SHARES_SOLD')}%
                    </Text>
                    <Text css={percentageStyles}>({percentage[0]}%-{percentage[1]}%)</Text>
                </HStack>
                <Slider
                    min={1}
                    value={percentage}
                    onChange={(event, newValue) => {
                        dispatch({type: 'shares', payload: newValue})
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    classes={{...sliderClasses}}
                    getAriaValueText={valuetext}
                />
            </Box>
        </Box>
    )
}
export default SideMenuFilters;
