import React, { FunctionComponent, useState } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SigninGeneral from '../SigninGeneral/SigninGeneral';
import { CurrentView } from './types';
import SigninEmail from '../SigninEmail';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import CheckEmail from '../CheckEmail';
import SignUp from '../SignUp';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import ResetPassword from '../ResetPassword/ResetPassword'; // importing FunctionComponent

type SigninContainerProps = { onClose: () => void };

const SigninContainer: FunctionComponent<SigninContainerProps> = ({
  onClose
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const signInContainerStyles = {
    padding: isMobile ? '5px 20px 20px 20px' : '5px 80px 20px 108px'
  };
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const [currentView, setCurrentState] = useState<CurrentView>('initial');
  const changeView = (
    currentView: CurrentView,
    email?: string,
    name?: string,
    password?: string
  ) => {
    setCurrentState(currentView);
    if (email) {
      setEmail(email);
    }
    if (name) {
      setName(name);
    }
    if (password) {
      setPassword(password);
    }
  };
  const getChildComponent = () => {
    switch (currentView) {
      case 'initial':
        return <SigninGeneral changeView={changeView} onClose={onClose} />;
      case 'email-sign-in':
        return <SigninEmail changeView={changeView} onClose={onClose} />;
      case 'forgot-password':
        return <ForgotPassword changeView={changeView} />;
      case 'check-email':
        return <CheckEmail changeView={changeView} />;
      case 'signup':
        return <SignUp changeView={changeView} onClose={onClose} />;
      case 'verify':
        return (
          <VerifyEmail
            email={email}
            changeView={changeView}
            onClose={onClose}
            name={name}
            password={password}
          />
        );
      case 'reset-password':
        return (
          <ResetPassword
            email={email}
            changeView={changeView}
            onClose={onClose}
          />
        );
    }
  };
  return <Box css={signInContainerStyles}>{getChildComponent()}</Box>;
};

export default SigninContainer;
