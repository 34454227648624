import { useContext } from 'react';
import Categories from './Categories';
import SelectView from './SelectView';
import { Box, Flex } from '@chakra-ui/react';
import Footer from 'components/Footer';
import SecretsViewContext from '../contexts';
import { DASHBOARD_VIEWS } from 'utils/Constants';
import { useLocation, useRouteMatch } from 'react-router-dom';

const SideMenuContainerStyles = {
  paddingTop: '41px',
  paddingRight: '25px'
};

const separatorStyles = {
  boxSizing: 'border-box',
  height: '1px',
  width: '187px',
  borderBottom: '1px solid #CCCCCC',
  opacity: '0.9',
  marginLeft: '37px'
};

const SelectViewContainer = {
  marginTop: '27px',
  marginLeft: '38px',
  marginRight: '38px'
};

const footerContainer = {
  marginTop: 'auto',
  color: '#802081',
  fontFamily: 'Lato',
  fontSize: '12px',
  letterSpacing: '0.4px',
  lineHeight: '15px',
  padding: '0 90px 26px 38px',
  textAlign: 'start'
};

const SideMenu = () => {
  const [view, setView] = useContext(SecretsViewContext);
  let location = useLocation();

  const selectedIconIndex = view === DASHBOARD_VIEWS.HORIZONTAL ? 0 : 1;
  const setSelectedIconIndex = (index) => {
    const newView =
      index === 0 ? DASHBOARD_VIEWS.HORIZONTAL : DASHBOARD_VIEWS.VERTICAL;
    setView(newView);
  };
  return (
    <Flex flexDir='column' css={SideMenuContainerStyles} h='full'>
      {!location.pathname.startsWith('/profile') && (
        <>
         <Box css={SelectViewContainer}>
        <a
          href='https://apps.apple.com/us/app/secretsville/id1549288830'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://ci4.googleusercontent.com/proxy/jwkXLXy89Ugx511I0sZh7yCLhHXxh8pT2-BLA6hOEgpL1gRESmjK-2sufuRSlhbh2UoXpvua-jWc8Pm2MYD_zk03sgAyhe5pGvssYam3GgbPytEEuYWHi_zrtNVDi_3vXFk=s0-d-e1-ft#https://ssl.gstatic.com/accounts/services/mail/msa/apple_app_store_badge_en.png'
            alt='Apple App Store'
          />
        </a>
        <br />
        <a
          href='https://play.google.com/store/apps/details?id=com.secretsville&hl=en&gl=US'
          target='_blank'
          rel='noreferrer'
        >
          <img
            src='https://ci3.googleusercontent.com/proxy/H39TaetrVBsbneMwKRnaq6h7bi_0g04ZWOC8yx-on4n3QwfPR4rEGhzXJ5uJpiV3r7QQpuRfs7OFHMXP5S9hjLUtA6AVQpa_81388klzhjc73UpBimV17dy3i8cO361-0XtGzA=s0-d-e1-ft#https://ssl.gstatic.com/accounts/services/mail/msa/google_play_store_badge_en.png'
            alt='Get it on Google Play'
          />
        </a>
        {/*<SelectView*/}
        {/*  selectedIconIndex={selectedIconIndex}*/}
        {/*  setSelectedIconIndex={setSelectedIconIndex}*/}
        {/*/>*/}
      </Box>
          <Box css={separatorStyles} />
        </>
      )}
      
      <Box css={footerContainer}>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SideMenu;
