import PaymentMethods from '../UserProfile/PaymentMethods';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { LayoutPositionEnum } from '../UserProfile/Layout';
import { useLocation } from 'react-router-dom';

const AddPaymentStyles = {
  overflow: 'auto',
  backgroundImage: 'linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 100%);'
};
const AddPayment = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');
  return (
    <Box h='calc(100vh - 68px)' css={AddPaymentStyles}>
      <Box
        m='auto'
        backgroundColor='#FFFFFF'
        margin={isMobile ? '10px auto' : '60px auto'}
        w='min(80%, 800px)'
        h='min(82%, 800px)'
        borderRadius='15px'
        display='grid'
        gridTemplateAreas={`'${LayoutPositionEnum.HEADER}' '${LayoutPositionEnum.MAIN}'`}
        gridTemplateRows='auto minmax(0, 1fr)'
      >
        <PaymentMethods showBackButton={false} token={token} />
      </Box>
    </Box>
  );
};

export default AddPayment;
