import axios from 'axios';
import {authRequestInterceptor} from "./utils/interceptor";

export const secretsApi = axios.create({
    baseURL: process.env.REACT_APP_SECRETS_API
});
secretsApi.interceptors.request.use(authRequestInterceptor);

export const usersApi = axios.create({
    baseURL: process.env.REACT_APP_USERS_API
});
usersApi.interceptors.request.use(authRequestInterceptor);

export const paymentsApi = axios.create({
    baseURL: process.env.REACT_APP_PAYMENTS_API
});
paymentsApi.interceptors.request.use(authRequestInterceptor);

export const notificationsApi = axios.create({
    baseURL: process.env.REACT_APP_NOTIFICATIONS_API
});
notificationsApi.interceptors.request.use(authRequestInterceptor);
