import {
  createMuiTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField
} from '@material-ui/core';
import {
  Box,
  Flex,
  Image,
  Select,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import React from 'react';
import plusIcon from '../../assets/icons/add.svg';
import minusIcon from '../../assets/icons/minus.svg';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const priceContainerStyles = {
  color: '#494949',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  textAlign: 'center',
  borderRadius: '19px',
  backgroundColor: '#FFFFFF',
  marginTop: '6px',
  padding: '0 9.5px 0 7.5px',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: '1px solid #D8D8D8',
  alignItems: 'center',
  height: '33px'
};
const textFieldStyles = {
  fontFamily: 'Lato',
  fontSize: '12px',
  borderRadius: '19px'
};

const labelStyles = {
  textAlign: 'start',
  fontFamily: 'Lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '17px',
  color: '#9E9E9E'
};
const authenticationLabelStyles = {
  textAlign: 'start',
  fontFamily: 'Lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '17px',
  color: '#252525'
};
const optionStyles = {
  boxSizing: 'border-box',
  // height: '33px',
  border: '1px solid #D8D8D8',
  borderRadius: '19px',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  fontSize: '14px',
  fontWeight: '300',
  letterSpacing: '0.47px',
  lineHeight: '17px',
  padding: '7.5px',
  minWidth: '87px',
  '&:hover': {
    cursor: 'pointer'
  }
};

const selectedOptionStyles = {
  ...optionStyles,
  color: '#802081',
  border: '2px solid #802081',
  fontWeight: 'unset'
};

const iconStyles = {
  '&:hover': {
    cursor: 'pointer'
  }
};

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '19px'
      },
      inputMarginDense: {
        paddingTop: '7px',
        paddingBottom: '7px'
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '19px'
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '6px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px 11px 7px 0'
      }
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: '0'
      }
    },
    MuiFormHelperText: {
      root: {
        color: 'red',
        fontFamily: 'Lato'
      }
    }
  }
});

const dateInputStyles = makeStyles({
  input: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    color: '#000000'
  },
  root: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '15px',
    letterSpacing: '0.4px',
    height: '100%',
    color: '#000000',
    border: '1px solid #D8D8D8',
    borderRadius: '16px',
    paddingLeft: '20px'
  }
});

const radioStyles = makeStyles({
  root: {
    color: '#802081!important',
    lineHeight: '14px',
    fontSize: '14px'
  },
  label: {}
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      background: '#EFEFEF'
    }
  }
}));

const Input = ({ type, label, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const effectiveClasses = rest.disabled ? classes : null;
  let InputField;
  switch (type) {
    case 'textField':
      InputField = getTextField(rest, effectiveClasses);
      break;
    case 'options':
      InputField = getCategoryField(rest, t);
      break;
    case 'price':
      InputField = getPriceField(rest);
      break;
    case 'dateTime':
      InputField = getDateTimeField(rest, t);
      break;
    case 'radio':
      InputField = getRadioField(rest, t);
      break;
    case 'authentication':
      InputField = getAuthenticationField(rest);
      break;
    case 'dropdown':
      InputField = getDropDownField(rest);
      break;
    case 'textarea':
      InputField = getTextAreaField(rest, effectiveClasses);
      break;
    default:
      InputField = getTextField(rest, effectiveClasses);
  }
  const labelClass =
    type === 'authentication' ? authenticationLabelStyles : labelStyles;
  return (
    <MuiThemeProvider theme={theme}>
      <Text css={labelClass}>{label}</Text>
      {InputField}
    </MuiThemeProvider>
  );
};

const getDropDownField = (props) => {
  const error =
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name];
  const helperText = error ? props.form.errors[props.field.name] : '';
  const inputProps = { ...props, ...props.field, ...props.form };
  if (props.field && props.field.value) {
    props.value = props.field.value;
  }
  return (
    <>
      <Select
        marginTop='6px'
        marginBottom='0'
        borderRadius='7px'
        placeholder={props.placeholder}
        size='sm'
        error={error}
        helperText={helperText}
        {...inputProps}
      >
        {props.options.map((option) => (
          <option value={typeof option === 'object' ? option.value : option}>
            {typeof option === 'object' ? option.label : option}
          </option>
        ))}
      </Select>
      {!!helperText && (
        <FormHelperText style={{ marginTop: '4px' }}>
          {' '}
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
const getTextField = (props, classes) => {
  const error =
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name];
  const helperText = error ? props.form.errors[props.field.name] : '';
  const inputProps = { ...props, ...props.field, ...props.form };
  if (props.field && props.field.value) {
    props.value = props.field.value;
  }
  let className = classes ? classes.root : null;
  const updatedTextFieldStyles = { ...textFieldStyles };
  if (props.payment) {
    updatedTextFieldStyles.borderRadius = '7px';
  }
  return (
    <TextField
      variant='outlined'
      css={updatedTextFieldStyles}
      margin='normal'
      size='small'
      className={className}
      error={error}
      helperText={helperText}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        disableUnderline: true,
        style: props.center ? { textAlign: 'center' } : {}
      }}
      InputProps={{
        style: props.payment ? { borderRadius: '7px' } : {}
      }}
      {...inputProps}
    />
  );
};

const getTextAreaField = (props, classes) => {
  const error =
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name];
  const helperText = error ? props.form.errors[props.field.name] : '';
  const inputProps = { ...props, ...props.field, ...props.form };
  if (props.field && props.field.value) {
    props.value = props.field.value;
  }
  let className = classes ? classes.root : null;
  const updatedTextFieldStyles = { ...textFieldStyles };
  if (props.payment) {
    updatedTextFieldStyles.borderRadius = '7px';
  }
  return (
    <TextField
      variant='outlined'
      css={updatedTextFieldStyles}
      margin='normal'
      size='small'
      multiline
      rows={6}
      className={className}
      error={error}
      helperText={helperText}
      maxRows={10}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        disableUnderline: true,
        style: props.center ? { textAlign: 'center' } : {}
      }}
      InputProps={{
        style: props.payment ? { borderRadius: '7px' } : {}
      }}
      {...inputProps}
    />
  );
};

const getDateTimeField = (props, t, selected = false) => {
  const dateClasses = dateInputStyles();
  const inputStyles = selected ? { border: '0.8px solid #802081' } : {};
  const inputProps = { ...props, ...props.field, ...props.form };
  if (props.field && props.field.value) {
    props.value = props.field.value;
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          style={{ width: props.isMobile ? '210px' : '300px' }}
          variant='inline'
          margin='none'
          InputProps={{
            classes: { ...dateClasses },
            shrink: true,
            style: { ...inputStyles },
            disableUnderline: true
          }}
          InputAdornmentProps={{ position: 'end', variant: 'outlined' }}
          InputLabelProps={{ classes: { input: dateClasses.root } }}
          id='date-time-picker-inline'
          onChange={props.onChooseDate}
          emptyLabel={t('DASHBOARD.DEFINE')}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          {...inputProps}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
const getCategoryField = (props, t) => {
  return (
    <Box>
      <Flex
        flexWrap='wrap'
        mt='10px'
        alignItems='stretch'
        gridRowGap='7px'
        gridColumnGap='9px'
      >
        {props.options &&
          props.options.map((option) => {
            let optionComponent, optionId, optionName;
            if (typeof option === 'object') {
              optionId = option.id;
              optionName = option.name;
            } else {
              optionId = option;
              optionName = option;
            }
            const styleClass =
              props.selectedOption === optionId
                ? { ...selectedOptionStyles }
                : { ...optionStyles };
            if (props.width) {
              styleClass.width = props.width;
              styleClass.padding = 'none';
            }
            if (props.size && props.size === 'small') {
              styleClass.minWidth = '53px';
            }
            if (optionId === 'DATE') {
              optionComponent = (
                <Box
                  key={optionId}
                  onClick={() => props.onChooseOption(option)}
                >
                  {getDateTimeField(
                    props,
                    t,
                    props.selectedOption === optionId
                  )}
                </Box>
              );
            } else {
              if (props.needsTranslation) {
                optionName = t(optionName);
              }
              if (props.prefix) {
                optionName = '$' + optionName;
              }
              optionComponent = (
                <Box
                  key={optionId}
                  css={styleClass}
                  onClick={() => props.onChooseOption(option)}
                >
                  {optionName}
                </Box>
              );
            }
            return optionComponent;
          })}
      </Flex>
      {props.selectedOption !== '24_HOURS' && props.error && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </Box>
  );
};
const getPriceField = (props) => {
  return (
    <Flex css={priceContainerStyles}>
      <Image
        css={iconStyles}
        src={minusIcon}
        w='23px'
        h='23px'
        maxWidth='unset'
        onClick={() => {
          if (props.value - props.sharePrice > 0) {
            props.updateTotal(props.value - props.sharePrice);
          }
        }}
      />
      <Box>
        {/*<Text textAlign="center">$ {props.value}</Text>*/}
        <TextField
          id='standard-number'
          value={props.value}
          onChange={(event) => {
            if (event.target.value % props.sharePrice === 0) {
              props.updateTotal(Number(event.target.value));
            }
          }}
          type='number'
          InputLabelProps={{
            shrink: true,
            style: { border: 'none' }
          }}
          inputProps={{
            style: { border: 'none', textAlign: 'center' },
            min: 10,
            step: 1
          }}
          InputProps={{
            disableUnderline: true
          }}
        />
        {/*<Text textAlign="center">$ 50</Text>*/}
      </Box>
      <Image
        css={iconStyles}
        src={plusIcon}
        w='23px'
        h='23px'
        maxWidth='unset'
        onClick={() => {
          props.updateTotal(props.value + props.sharePrice);
        }}
      />
    </Flex>
  );
};
const getRadioField = (props, t) => {
  const { value, ...newProps } = props;
  const radioStylesClasses = radioStyles();
  const error =
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name];
  const helperText = error ? props.form.errors[props.field.name] : '';
  const field = props.field;
  return (
    <Box textAlign='start'>
      <FormControl component='fieldset'>
        <RadioGroup
          name='seller_account_id'
          defaultValue={value}
          {...field}
          {...newProps}
        >
          {props.options.map((option) => {
            const key = props.key ? props.key : 'bank_name';
            return (
              <FormControlLabel
                classes={{ ...radioStylesClasses }}
                key={option.id}
                value={option.id.toString()}
                control={<Radio classes={{ ...radioStylesClasses }} />}
                label={option[key]}
              />
            );
          })}
        </RadioGroup>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
const getAuthenticationField = (props) => {
  const error =
    !!props.form.touched[props.field.name] &&
    !!props.form.errors[props.field.name];
  const inputProps = { ...props, ...props.field, ...props.form };
  const helperText = error ? props.form.errors[props.field.name] : '';
  if (props.field && props.field.value) {
    props.value = props.field.value;
  }
  return (
    <TextField
      variant='outlined'
      margin='normal'
      size='small'
      error={error}
      helperText={helperText}
      type={props.detailedType ? props.detailedType : 'text'}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        disableUnderline: true,
        style: {
          border: '1px solid #E6E6E6',
          borderRadius: '0 17.5px 17.5px 0',
          backgroundColor: '#FFFFFF'
        }
      }}
      InputProps={{
        style: {
          backgroundColor: '#802081',
          borderRadius: '17.5px',
          border: 'none'
        },
        startAdornment: (
          <InputAdornment position='start'>
            <Image src={props.icon} />
          </InputAdornment>
        )
      }}
      {...inputProps}
    />
  );
};
export default Input;
