// Request interceptor for API calls

import {Auth} from "aws-amplify";

export const authRequestInterceptor = async (request: any) => {
    try {
        const authData = await Auth.currentSession();
        request.headers = {
            'Authorization': `Bearer ${authData.getAccessToken().getJwtToken()}`
        }
        return request;
    } catch {
        return request;
    }
};
