import { Flex, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../routes/routes';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <Flex justifyContent='space-between' marginTop='13px'>
        <Link as={RouterLink} to='/about-us'>
          {t('DASHBOARD.ABOUT_US')}
        </Link>
        <Link as={RouterLink} to='/contact-us'>
          {t('DASHBOARD.CONTACT_US')}
        </Link>
      </Flex>
      <Flex justifyContent='space-between' marginTop='13px'>
        <Link as={RouterLink} to='privacy'>
          {t('DASHBOARD.PRIVACY_POLICY')}
        </Link>
        <Link as={RouterLink} to='/terms-of-use'>
          {t('DASHBOARD.TERMS_OF_USE')}
        </Link>
      </Flex>
      <Link as={RouterLink} to='/discover'>
        <Text color='#4E4E4E' lineHeight='20px' marginTop='17px' width='152'>
          {t('DASHBOARD.ALL_RIGHTS_RESERVED')}
        </Text>
      </Link>
    </>
  );
};

export default Footer;
