import { createIcon } from '@chakra-ui/icons';
// using `path`
export const ProfileIcon = createIcon({
  displayName: 'ProfileIcon',
  viewBox: '0 0 53 53',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-308.000000, -13.000000)'>
        <g transform='translate(0.000000, 7.000000)'>
          <g>
            <g transform='translate(308.000000, 6.000000)'>
              <path
                d='M26,41.9182692 C28.6795006,41.9182692 31.2003087,41.2484041 33.5625,39.9086538 C35.8541781,38.6746733 37.7403772,36.9647545 39.2211538,34.7788462 C39.1858973,33.4390959 38.3573799,32.2051338 36.7355769,31.0769231 C35.3253135,30.1249952 33.5272545,29.349362 31.3413462,28.75 C29.3669773,28.2211512 27.5777324,27.9567308 25.9735577,27.9567308 C24.369383,27.9567308 22.5801381,28.2211512 20.6057692,28.75 C18.4198609,29.349362 16.63943,30.1249952 15.2644231,31.0769231 C13.6426201,32.2051338 12.8141027,33.4390959 12.7788462,34.7788462 C14.2596228,36.9647545 16.1458219,38.6746733 18.4375,39.9086538 C20.8349479,41.2484041 23.355756,41.9182692 26,41.9182692 Z M26,10.6105769 C24.8365326,10.6105769 23.7435949,10.9102534 22.7211538,11.5096154 C21.6987128,12.1089774 20.8878235,12.9198667 20.2884615,13.9423077 C19.6890996,14.9647487 19.3894231,16.0576865 19.3894231,17.2211538 C19.3894231,18.3846212 19.6890996,19.477559 20.2884615,20.5 C20.8878235,21.522441 21.6987128,22.3333303 22.7211538,22.9326923 C23.7435949,23.5320543 24.8365326,23.8317308 26,23.8317308 C27.1634674,23.8317308 28.2564051,23.5320543 29.2788462,22.9326923 C30.3012872,22.3333303 31.1121765,21.522441 31.7115385,20.5 C32.3109004,19.477559 32.6105769,18.3846212 32.6105769,17.2211538 C32.6105769,16.0576865 32.3109004,14.9647487 31.7115385,13.9423077 C31.1121765,12.9198667 30.3012872,12.1089774 29.2788462,11.5096154 C28.2564051,10.9102534 27.1634674,10.6105769 26,10.6105769 Z'
                fill='currentColor'
                fillRule='nonzero'
                opacity='0.901523'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  )
});
