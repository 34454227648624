import * as React from 'react';
import { useRef } from 'react';
import { useAuth } from 'utils/AmplifyContext';
import { useTranslation } from 'react-i18next';
import { getRelativePath } from '../../routes/routes';
import ProfileImage from 'assets/icons/profile.png';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { AuthenticationModalContext } from 'pages/Dashboard/contexts';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Image,
  Link,
  LinkProps as CLinkProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text
} from '@chakra-ui/react';

import { MySecretsIcon, ProfileIcon, WalletIcon } from 'icons';
import { notificationsApi, usersApi } from '../../api';
import i18n from 'i18next';

const MenuLink = ({
  to,
  icon,
  title,
  ...rest
}: LinkProps & CLinkProps & { icon: React.ReactNode }) => {
  return (
    <Link as={RouterLink} to={to} {...rest}>
      <HStack spacing='19px'>
        {icon}
        <Text>{title}</Text>
      </HStack>
    </Link>
  );
};

function Profile() {
  const { t } = useTranslation();
  const { isAuthenticated, user, logout, initialize } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const openAuthenticationDialog = React.useContext(AuthenticationModalContext);
  const handleMenuClick = () => {
    if (!isAuthenticated) {
      (openAuthenticationDialog as () => {})?.();
    }
  };

  const onLangChanged = (event: any) => {
    const lang = event.target.value;
    notificationsApi
      .put(`users/me/notifications/settings`, {
        language: event.target.value,
        receive_notifications: true
      })
      .then((data) => {
        initialize();
        if (lang === 'ARABIC') {
          document.body.style.direction = 'rtl';
          document.body.classList.add('page-rtl');
          i18n.changeLanguage('ar');
        } else {
          i18n.changeLanguage('en');
          document.body.style.direction = 'ltr';
          document.body.classList.remove('page-rtl');
        }
      });
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          colorScheme='brand'
          minW={0}
          h={0}
          boxSize={7}
          px={0}
          borderRadius='full'
          onClick={handleMenuClick}
          _focus={{ boxShadow: '0 0 0 3px var(--chakra-colors-brand-500)' }}
        >
          <Image
            objectFit='cover'
            boxSize={7}
            borderRadius='full'
            src={user?.profile_photo ?? ProfileImage}
            _hover={{ cursor: 'pointer' }}
          />
        </MenuButton>
        {isAuthenticated && (
          <MenuList minW={0} zIndex={23}>
            <MenuItem>
              <MenuLink
                to={getRelativePath('MY_SECRETS')}
                title={t('DASHBOARD.MY_SECRETS')}
                icon={<MySecretsIcon boxSize='1.5rem' color='#4E4E4E' />}
              />
            </MenuItem>
            <MenuItem>
              <MenuLink
                to={getRelativePath('MY_TRANSACTIONS')}
                title={t('DASHBOARD.MY_TRANSACTIONS')}
                icon={<WalletIcon boxSize='1.5rem' color='#4E4E4E' />}
              />
            </MenuItem>
            <MenuItem>
              <MenuLink
                title={t('DASHBOARD.MY_PROFILE')}
                to={getRelativePath('PROFILE')}
                icon={<ProfileIcon boxSize='2.1rem' color='#4E4E4E' />}
                position='relative'
                left='-8px'
              />
            </MenuItem>
            <MenuItem>
              <Select
                onClick={(e) => {
                  // Only Stop Propagation for first click, send one goes to onSelect
                  if (e.button === 0) {
                    e.stopPropagation();
                  }
                }}
                value={user?.language}
                onChange={(e) => {
                  onLangChanged(e);
                }}
              >
                <option value='ENGLISH'>English</option>
                <option value='ARABIC'>العربية</option>
              </Select>
            </MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {t('DASHBOARD.LOGOUT')}
            </MenuItem>
          </MenuList>
        )}
      </Menu>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody>{t('DASHBOARD.LOGOUT_CONFIRM')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t('DASHBOARD.NO')}
              </Button>
              <Button
                colorScheme='red'
                onClick={() => {
                  logout();
                  onClose();
                }}
                ml={3}
              >
                {t('DASHBOARD.YES')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default Profile;
