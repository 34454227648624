import { getHttpService } from '../httpService';
import { SetOptional } from 'type-fest';

const getEndPoint = () =>
  ({
    userDetails: (userId: number) => `/users/${userId}` as const,
    currentUserDetails: '/users/me'
  } as const);

export type GetUserDetailsResponse = {
  id: number;
  name: string;
  profile_photo: null | { url: string; path: string };
  rate: number;
  email: string;
  phone: string;
  is_trusted: boolean;
  language: 'ENGLISH' | 'ARABIC';
  receive_notifications?: boolean;
  is_social?: boolean;
  is_verified: boolean;
};

async function getCurrentUserDetails(): Promise<GetUserDetailsResponse> {
  const response = await getHttpService('users').get<GetUserDetailsResponse>(
    getEndPoint()['currentUserDetails']
  );

  return response.data;
}

type UpdateUserIntoData = SetOptional<
  Omit<
    GetUserDetailsResponse,
    'rate' | 'is_trusted' | 'email' | 'profile_photo'
  > & { profile_photo: string },
  'phone' | 'profile_photo' | 'language'
>;

async function updateUserInfo({ id, ...data }: UpdateUserIntoData) {
  const response = await getHttpService('users').put(
    getEndPoint()['userDetails'](id),
    { ...data }
  );
  return response.data;
}

export { getCurrentUserDetails, updateUserInfo };
