import { useTranslation } from 'react-i18next';
import Icon from '@chakra-ui/icon';
import { ReactComponent as PaymentIcon } from '../../assets/icons/payment.svg';
import { GoBack, layoutCss, layoutMobileCss } from './Shared';
import { LayoutItem } from './Layout';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { paymentsApi } from '../../api';
import { getExpiryDate } from '../../utils/secrets';
import PaymentForm from '../../components/PaymentForm/PaymentForm';
import SavedCreditCard from '../../components/SavedCreditCard/SavedCreditCard';
import SavedPaypal from '../../components/SavedPaypal/SavedPaypal';

const backDropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute'
  }
}));

type paymentMethodsProps = {
  showBackButton?: boolean;
  token?: string | null;
};
const PaymentMethods: FunctionComponent<paymentMethodsProps> = ({
  showBackButton = true,
  token
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const layoutStyles = isMobile ? { ...layoutMobileCss } : { ...layoutCss };
  const [savedPayment, setSavedPayment] = useState<{
    payment_method: 'CARD' | 'PAYPAL';
    card_number: string;
  } | null>(null);
  const backDropClasses = backDropStyles();
  useEffect(() => {
    setTimeout(() => {
      if (!token) {
        getSavedPayments();
      } else {
        setIsEditing(true);
      }
    }, 1000);
  }, []);

  const getSavedPayments = () => {
    paymentsApi
      .get(
        'payment-methods/saved',
        token
          ? {
              headers: { Authorization: `Bearer ${token}` }
            }
          : {}
      )
      .then(
        (data) => {
          if (data.data && data.data.length) {
            setSavedPayment(data.data[0] as any);
            setIsLoading(false);
            setIsEditing(false);
          } else {
            setIsEditing(true);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
  };

  const listenPayPalFinish = (setSubmitting: any, approval_url: string) => {
    let win = null as any;
    const checkChild = () => {
      if (win && win.closed) {
        clearInterval(timer);
        setSubmitting(false);
        window.removeEventListener('message', onMessageReceived);
      }
    };
    const timer = setInterval(checkChild, 5000);
    const onMessageReceived = (ev: any) => {
      if (ev.data.status && ev.data.status === 'success') {
        paymentsApi
          .post(
            'payment-token/approve',
            {
              approval_token_id: ev.data.approvalToken
            },
            token
              ? {
                  headers: { Authorization: `Bearer ${token}` }
                }
              : {}
          )
          .then(
            (data) => {
              clearInterval(timer);
              setSubmitting(false);
              if (token) {
                setIsOpen(true);
              } else {
                toast({
                  title: 'Payment Method added',
                  description: 'payment method has been successfully added',
                  status: 'success',
                  duration: 9000,
                  isClosable: true
                });
                getSavedPayments();
              }
            },
            () => {
              clearInterval(timer);
              setSubmitting(false);
              toast({
                title: 'Failed to add payment method',
                description: 'Failed to add Paypal account',
                status: 'error',
                duration: 9000,
                isClosable: true
              });
              getSavedPayments();
              window.removeEventListener('message', onMessageReceived);
            }
          );
      } else {
        if (Object.prototype.hasOwnProperty.call(ev.data, 'status')) {
          clearInterval(timer);
          setSubmitting(false);
          toast({
            title: 'Failed to add payment method',
            description: 'Failed to add Paypal account',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          getSavedPayments();
          window.removeEventListener('message', onMessageReceived);
        }
      }
    };
    win = window.open(
      approval_url,
      '',
      `width=500, height=500, resizable=no, copyhistory=no`
    );
    window.addEventListener('message', onMessageReceived, false);
  };
  const onSubmit = async (values: any, formikBag: any) => {
    const { setSubmitting } = formikBag;
    setSubmitting(true);
    const { payment_method, ...creditValues } = values;
    const data = {
      payment_method_type: payment_method === 'paypal' ? 'PAYPAL' : 'CARD'
    } as any;
    if (payment_method === 'credit') {
      data['card'] = {
        ...creditValues,
        expiry_date: getExpiryDate(
          values['expiry_year'],
          values['expiry_month']
        )
      };
    } else {
      data['return_url'] = window.location.origin + '/payment?status=success';
      data['cancel_url'] = window.location.origin + '/payment?status=failure';
    }
    paymentsApi
      .post(
        'payment-method',
        data,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` }
            }
          : {}
      )
      .then(
        (data) => {
          if (payment_method !== 'credit') {
            listenPayPalFinish(setSubmitting, data.data.approval_url);
          } else {
            setSubmitting(false);
            if (token) {
              setIsOpen(true);
            } else {
              toast({
                title: 'Payment Method added',
                description: 'payment method has been successfully added',
                status: 'success',
                duration: 9000,
                isClosable: true
              });
              getSavedPayments();
            }
          }
        },
        (error) => {
          setSubmitting(false);
          toast({
            title: 'Failed to add payment method',
            description:
              'Failed to add credit card, please check the info and try again',
            status: 'error',
            duration: 9000,
            isClosable: true
          });
          getSavedPayments();
        }
      );
  };
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        onClose={onClose}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef.current}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {t('ADD_PAYMENT.SUCCESS_TITLE')}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {t('ADD_PAYMENT.SUCCESS_DESCRIPTION')}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>{t('CHECKOUT.OK')}</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {showBackButton && (
        <GoBack
          title={t('USER_PROFILE.PAYMENT_METHODS.BACK_TITLE')}
          Icon={
            <Icon w='28px' h='21px' viewBox='0 0 45 34' color=''>
              <PaymentIcon />
            </Icon>
          }
        />
      )}
      <LayoutItem section='main' {...layoutStyles}>
        {isEditing && (
          <PaymentForm
            onSubmit={onSubmit}
            submitLabel={t('USER_PROFILE.COMMON.SAVE')}
          />
        )}
        {!isEditing && (
          <>
            <Backdrop className={backDropClasses.backdrop} open={isLoading}>
              <CircularProgress color='inherit' />
            </Backdrop>
            {savedPayment?.payment_method === 'CARD' && (
              <SavedCreditCard
                showEdit={true}
                cardNumber={savedPayment.card_number}
                editCard={() => {
                  setIsEditing(true);
                }}
              />
            )}
            {savedPayment?.payment_method === 'PAYPAL' && (
              <SavedPaypal
                showEdit={true}
                editCard={() => {
                  setIsEditing(true);
                }}
              />
            )}
          </>
        )}
      </LayoutItem>
    </>
  );
};
export default PaymentMethods;
