import {
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  useMediaQuery
} from '@chakra-ui/react';

export enum LayoutPositionEnum {
  HEADER = 'header',
  MAIN = 'main'
}

type LayoutPosition = `${LayoutPositionEnum}`;
type LayoutItemProps = {
  section: LayoutPosition;
} & GridItemProps;

export function LayoutItem({ section, children, ...rest }: LayoutItemProps) {
  return (
    <GridItem {...rest} gridArea={section}>
      {children}
    </GridItem>
  );
}

function Layout({ children, ...rest }: GridProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Grid
      bg='white'
      margin={isMobile ? '10px auto' : '60px auto'}
      w='min(80%, 800px)'
      h='min(95%, 700px)'
      borderRadius='15px'
      gridTemplateAreas={`'${LayoutPositionEnum.HEADER}' '${LayoutPositionEnum.MAIN}'`}
      gridTemplateRows='auto minmax(0, 1fr)'
      {...rest}
    >
      {children}
    </Grid>
  );
}

export default Layout;
