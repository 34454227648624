import { HStack } from '@chakra-ui/react';
import { NAV_ITEMS } from '../../utils/Constants';
import NavItem from '../NavItem';

const NavItems = () => {
  return (
    <HStack
      spacing='37px'
      paddingRight={{
        base: '0',
        md: '100px'
      }}
    >
      {NAV_ITEMS.map((navItem) => {
        return <NavItem key={navItem.label} {...navItem} />;
      })}
    </HStack>
  );
};

export default NavItems;
