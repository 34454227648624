import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Image,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import logo from '../../assets/icons/logo.svg';
import NavItems from '../NavItems';
import Profile from '../Profile';
import Notifications from '../Notifications';
import Search from '../Search';
import { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiltersContext } from '../../pages/Dashboard/contexts';
import { FiMenu } from 'react-icons/all';
import SideMenu from '../../pages/Dashboard/components/SideMenu';
import { Text } from '@chakra-ui/layout';
import { usersApi } from '../../api';
import { useAuth } from '../../utils/AmplifyContext';
import i18n from 'i18next';

const NavContainerStyles = {
  color: '#494949',
  width: '100%',
  backgroundColor: 'white',
  padding: '7px 40px 6px 22px',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: '10px'
};

const navLinksContainerStyles = {
  flex: 2,
  display: 'flex',
  justifyContent: 'center'
};
const navLinksMobileContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
  flex: '4'
};
const NavBar = () => {
  const { isAuthenticated } = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [userName, setUserName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state, dispatch } = useContext(FiltersContext);
  const queryParams = new URLSearchParams(useLocation().search);
  const userId = queryParams.get('userId');
  useEffect(() => {
    if (userId) {
      usersApi.get(`users/${userId}`).then((data) => {
        setUserName(data.data.name);
      });
    }
  }, [userId]);
  return (
    <>
      {isMobile && !userId && (
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
        >
          <DrawerContent>
            <SideMenu onClose={onClose} />
          </DrawerContent>
        </Drawer>
      )}
      <Flex bg='white' w='100%' css={NavContainerStyles}>
        {isMobile && !userId && (
          <IconButton
            onClick={onOpen}
            variant='outline'
            aria-label='open menu'
            icon={<FiMenu />}
          />
        )}
        <Flex height='full' align='center'>
          {!userId && (
            <NavLink exact to={'/discover'}>
              <Image src={logo} h='37px' w='153px' />
            </NavLink>
          )}
          {userId && <Image src={logo} h='37px' w='153px' />}
          <Box display={{ base: 'none', md: 'block' }}>
            <Search
              value={state.name}
              onSearch={(search) => {
                dispatch({ type: 'search', payload: search });
              }}
            />
          </Box>
        </Flex>
        {!isMobile && (
          <Box css={navLinksContainerStyles}>
            <NavItems />
          </Box>
        )}
        {!userId && (
          <HStack spacing='35px'>
            <Box position='relative' _hover={{ cursor: 'pointer' }}>
              {isAuthenticated && <Notifications />}
            </Box>
            <Profile />
          </HStack>
        )}
        {userId && (
          <Text fontSize='14px' fontFamily='lato' maxWidth='170px' isTruncated>
            Welcome, {userName}
          </Text>
        )}
        {isMobile && !userId && (
          <Box css={navLinksMobileContainerStyles}>
            <NavItems />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default NavBar;
