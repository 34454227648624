export const amplifyConfig = {
    aws_project_region: process.env.REACT_APP_AWS_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin+'/discover?social=true',
        redirectSignOut: window.location.origin,
        responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code,
        clientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    federationTarget: 'COGNITO_USER_POOLS',
};
