import notificationsIcon from '../../assets/icons/notifications.svg';
import NotificationsMenu from 'react-notifications-menu';

import { Image } from '@chakra-ui/image';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useMediaQuery
} from '@chakra-ui/react';
import NotificationsList from '../NotificationsList/NotificationsList';
import { useAuth } from '../../utils/AmplifyContext';

const Notifications = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isAuthenticated, user } = useAuth();
  return (
    <>
      <Popover isLazy>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Box>
                {user && !!user.messages_count && (
                  <Box
                    position='absolute'
                    top='-7px'
                    right='-7px'
                    backgroundColor='#CC0099'
                    fontSize='14px'
                    fontWeight='bold'
                    letterSpacing='0.22px'
                    lineHeight='17px'
                    borderRadius='50%'
                    padding='0 5px'
                    color='#FFFFFF'
                  >
                    {user.messages_count}
                  </Box>
                )}
                <Box>
                  <Image
                    _hover={{ cursor: 'pointer' }}
                    src={notificationsIcon}
                    w='24px'
                    h='29px'
                  />
                </Box>
              </Box>
            </PopoverTrigger>
            <PopoverContent
              paddingInlineStart='0'
              paddingInlineEnd='0'
              width={isMobile ? '400px' : '450px'}
              h='500px'
            >
              <PopoverArrow />
              <PopoverBody paddingInlineStart='0' paddingInlineEnd='0'>
                <NotificationsList onClose={onClose} />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
      {/*<NotificationsMenu*/}
      {/*  data={[*/}
      {/*    {*/}
      {/*      image:*/}
      {/*        'https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&q=70&fm=webp',*/}
      {/*      message: 'Someone revealed a secret',*/}
      {/*      detailPage: '/discover',*/}
      {/*      receivedTime: '12h ago'*/}
      {/*    },*/}
      {/*    {*/}
      {/*      image:*/}
      {/*        'https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&q=70&fm=webp',*/}
      {/*      message: 'Someone revealed a secret',*/}
      {/*      detailPage: '/discover',*/}
      {/*      receivedTime: '12h ago'*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*  icon={notificationsIcon}*/}
      {/*/>*/}
    </>
  );
};
;

export default Notifications;
