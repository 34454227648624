import React, { FunctionComponent } from 'react';
import payPalIcon from '../../assets/images/paypal.svg';
import { Box, Image } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/layout';
import { EditIcon } from '@chakra-ui/icons';

type SavedPaypalProps = {
  editCard?: () => void;
  showEdit: boolean;
};

const SavedPaypal: FunctionComponent<SavedPaypalProps> = ({
  editCard,
  showEdit
}) => {
  return (
    <Box
      maxWidth='445px'
      border='1px solid #CCCCCC'
      borderRadius='8px'
      padding='8px'
    >
      <Flex justifyContent='space-between'>
        <Image src={payPalIcon} />
        {showEdit && (
          <EditIcon
            onClick={editCard}
            _hover={{
              cursor: 'pointer'
            }}
          />
        )}
      </Flex>
      <Box mt='16px'>
        <Text textAlign='start'>Paypal</Text>
      </Box>
    </Box>
  );
};

export default SavedPaypal;
