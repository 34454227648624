//@ts-nocheck

import { createContext, useContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from './config';
import {
  getCurrentUserDetails,
  GetUserDetailsResponse
} from 'api/user-profile';

Amplify.configure(amplifyConfig);

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: (GetUserDetailsResponse & { profile_photo: string }) | null;
}

interface AuthContextValue extends AuthState {
  platform: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (
    email: string,
    password: string,
    name: string,
    familyName: string
  ) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  updateUser: () => Promise<void>;
  passwordChange: (oldPassword: string, newPassword: string) => Promise<void>;
  initialize: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: any | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type PasswordChangeAction = {
  type: 'PASSWORD_CHANGE';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | PasswordChangeAction;

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_CHANGE: (state: AuthState): AuthState => ({ ...state })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  passwordChange: () => Promise.resolve(),
  initialize: () => Promise.resolve()
});

export const useAuth = () => {
  const user = useContext(AuthContext);
  return user;
};
export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async (): Promise<void> => {
    try {
      await Auth.currentAuthenticatedUser();
      const user = await Auth.currentUserInfo();

      const currentUser = await getCurrentUserDetails();
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: {
            id: currentUser.id,
            profile_photo: currentUser.profile_photo?.url ?? null,
            email: currentUser.email,
            name: currentUser.name,
            phone: currentUser.phone,
            rate: currentUser.rate,
            messages_count: currentUser.messages_count,
            language: currentUser.language,
            receive_notifications: currentUser.receive_notifications,
            is_social: currentUser.is_social
          }
        }
      });

      // Here you should extract the complete user profile to make it
      // available in your entire app.
      // The auth state only provides basic information.
    } catch (error) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);

    if (user.challengeName) {
      console.error(
        `Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`
      );
      return;
    }

    try {
      const currentUser = await getCurrentUserDetails();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: currentUser.id,
            profile_photo: currentUser.profile_photo?.url ?? null,
            email: currentUser.email,
            name: currentUser.name,
            phone: currentUser.phone,
            rate: currentUser.rate,
            messages_count: currentUser.messages_count,
            language: currentUser.language,
            receive_notifications: currentUser.receive_notifications,
            is_social: currentUser.is_social
          }
        }
      });
    } catch (e) {
      throw new Error('Invalid credentials');
    }
  };

  const updateUser = async () => {
    const currentUser = await getCurrentUserDetails();

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: currentUser.id,
          profile_photo: currentUser.profile_photo?.url ?? null,
          email: currentUser.email,
          name: currentUser.name,
          phone: currentUser.phone,
          rate: currentUser.rate,
          messages_count: currentUser.messages_count,
          language: currentUser.language,
          receive_notifications: currentUser.receive_notifications,
          is_social: currentUser.is_social
        }
      }
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (
    email: string,
    password: string,
    name: string,
    familyName: string
  ): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        email,
        family_name: familyName
      }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };
  const passwordChange = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    dispatch({
      type: 'PASSWORD_CHANGE'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        updateUser,
        passwordChange,
        initialize
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
