import React from 'react';
import { DASHBOARD_VIEWS } from '../../utils/Constants';

const SecretsViewContext = React.createContext([
  DASHBOARD_VIEWS.HORIZONTAL
]);

const AuthenticationModalContext = React.createContext(
  () => {}
);
const TrailerContext = React.createContext(null);
const TrailerCoverContext = React.createContext(null);
const FiltersContext = React.createContext(null);
const SearchContext = React.createContext(null);
const VideoPlayContext = React.createContext(null);
export {
  FiltersContext,
  AuthenticationModalContext,
  SearchContext,
  VideoPlayContext,
  TrailerContext,
  TrailerCoverContext
};
export default SecretsViewContext;
