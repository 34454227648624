import { createIcon } from '@chakra-ui/icons';
// using `path`
export const PaymentIcon = createIcon({
  displayName: 'PaymentIcon',
  viewBox: '0 0 45 34',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-58.000000, -543.000000)'
        fill='currentColor'
        fillRule='nonzero'
      >
        <g transform='translate(58.000000, 543.000000)'>
          <path d='M40.4844796,10.715602 L17.3416224,10.715602 C15.1008061,10.715602 13.2640714,12.5523367 13.2640714,14.7931531 L13.2640714,29.4502959 C13.2640714,31.6911122 15.1008061,33.5278469 17.3416224,33.5278469 L40.4844796,33.5278469 C42.7252959,33.5278469 44.5620647,31.6911122 44.5620647,29.4502959 L44.5620647,14.7931531 C44.5712143,12.5431531 42.7252959,10.715602 40.4844796,10.715602 Z M42.3671327,29.505398 C42.3671327,30.3135612 41.7059082,30.9839694 40.8885612,30.9839694 L17.2957041,30.9839694 C16.4875408,30.9839694 15.8171327,30.3227449 15.8171327,29.505398 L15.8171327,19.1921327 L42.3671327,19.1921327 L42.3671327,29.505398 Z M42.3671327,15.3074388 L15.8171327,15.3074388 L15.8171327,14.7472347 C15.8171327,13.9390714 16.4783571,13.2686633 17.2957041,13.2686633 L40.8885612,13.2686633 C41.6967245,13.2686633 42.3671327,13.9298878 42.3671327,14.7472347 L42.3671327,15.3074388 Z' />
          <path d='M17.0477449,8.19927551 L31.4018265,8.19927551 L28.4814184,2.2849898 C27.480398,0.264581633 25.0283571,-0.56194898 23.007949,0.439071429 L2.27121429,10.688051 C0.250806122,11.6890714 -0.57572449,14.1411122 0.425295918,16.1615204 L6.92733673,29.3033571 C7.79978571,31.0758061 9.8110102,31.9390714 11.6569286,31.4339694 C11.1059082,30.5064184 10.7936633,29.4319286 10.7936633,28.2839694 L10.7936633,14.4625408 C10.7844796,11.0186633 13.6038673,8.19927551 17.0477449,8.19927551 Z' />
          <path d='M19.9957041,29.3951939 C20.7946837,29.3951939 21.5201939,29.0370306 21.9977449,28.4676429 C22.4844796,29.0370306 23.2008061,29.3951939 23.9997857,29.3951939 C25.4599898,29.3951939 26.6446837,28.2105 26.6446837,26.7502959 C26.6446837,25.2900918 25.4599898,24.105398 23.9997857,24.105398 C23.2008061,24.105398 22.4752959,24.4635612 21.9977449,25.032949 C21.5110102,24.4635612 20.7946837,24.105398 19.9957041,24.105398 C18.5355,24.105398 17.3508061,25.2900918 17.3508061,26.7502959 C17.3508061,28.2105 18.5355,29.3951939 19.9957041,29.3951939 Z' />
        </g>
      </g>
    </g>
  )
});
