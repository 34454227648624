import { CurrentView } from '../SigninContainer/types';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import backIcon from '../../assets/icons/back_black.svg';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import emailIcon from '../../assets/icons/email_icon.svg';
import Input from '../Input/Input';
import * as Yup from 'yup';
import { useAuth } from '../../utils/AmplifyContext';
import { Grid } from '@material-ui/core';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

type ResetPasswordProps = {
  email: string;
  changeView: (currentView: CurrentView) => void;
  onClose: () => void;
};

const forgotPasswordStyles = {
  color: '#494949',
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: 500,
  letterSpacing: '0',
  lineHeight: '19px'
} as const;

const resetPasswordButtonStyles = {
  marginTop: '42px',
  width: '100%',
  backgroundColor: '#802081',
  borderRadius: '23.5px',
  color: '#FFFFFF',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  fontFamily: 'lato',
  height: '48px',
  padding: '11px 29px 11px 29px',
  boxSizing: 'border-box',
  fontWeight: 'bold'
} as const;

const enterEmailStyles = {
  color: '#252525',
  fontFamily: 'Lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '17px',
  marginTop: '13px',
  textAlign: 'center'
} as const;

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Invalid code'),
  newPassword: Yup.string()
    .min(8)
    .required('Invalid password(has to be at least 8 characters)')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});
const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  email,
  changeView,
  onClose
}) => {
  const { t } = useTranslation();
  const user = useAuth();
  const [error, setError] = useState('');
  const onSubmit = async (values: any, formikBag: any) => {
    const { setSubmitting } = formikBag;
    setSubmitting(true);
    try {
      const resetResponse = await user.passwordReset(
        email,
        values.code,
        values.newPassword
      );
      setSubmitting(false);
      changeView('email-sign-in');
    } catch (e: any) {
      setError(e.message ?? 'Something went wrong');
    }
  };
  return (
    <Box mt='10px'>
      <Flex onClick={() => changeView('initial')}>
        <Image src={backIcon} _hover={{ cursor: 'pointer' }} />
        <Text css={forgotPasswordStyles} mr='36px' ml='36px'>
          {t('AUTHENTICATION.SET_NEW_PASSWORD')}
        </Text>
      </Flex>
      <Text css={enterEmailStyles}>
        {t('AUTHENTICATION.EMAIL_NOTIFICATION_SENT')}
      </Text>
      <Box mt='36px'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={1}>
                {error && (
                  <Grid item xs={12}>
                    <Alert status='error'>
                      <AlertIcon />
                      <AlertTitle>{error}</AlertTitle>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    name='code'
                    label={t('AUTHENTICATION.VERIFICATION_CODE')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='text'
                    fullWidth
                    component={Input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='newPassword'
                    label={t('AUTHENTICATION.NEW_PASSWORD')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='password'
                    fullWidth
                    component={Input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='confirmPassword'
                    label={t('AUTHENTICATION.CONFIRM_PASSWORD')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='password'
                    fullWidth
                    component={Input}
                  />
                </Grid>
              </Grid>
              <Button
                css={resetPasswordButtonStyles}
                disabled={isSubmitting || !isValid}
                type='submit'
              >
                {t('AUTHENTICATION.SET_NEW_PASSWORD')}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ResetPassword;
