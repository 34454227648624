import { SetOptional } from 'type-fest';
import { getHttpService } from '../httpService';
import { useAuth } from '../../utils/AmplifyContext';

const getUserId = () => {
  return 1;
};

const getEndPoint = () =>
  ({
    bankDetails: (userId: number) => `/users/${userId}/bank_account`,
    addBankItem: (userId: number) => `/users/${userId}/bank_account`,
    editBankDetailsItem: (userId: number, bankAccountId: number) =>
      `/users/${userId}/bank_account/${bankAccountId}` as const
  } as const);

export type BankingDetailsItemResponse = {
  id: number;
  account_holder_name: string;
  bank_name: string;
  bank_account_number: string;
  routing_aba_number: string;
  swift_code: string;
  additional_info: string;
  is_default: boolean;
};

export type GetBankingDetailsResponse = {
  data: Array<BankingDetailsItemResponse>;
};

async function getBankingDetails(
  id: number
): Promise<GetBankingDetailsResponse> {
  const response = await getHttpService('users').get<GetBankingDetailsResponse>(
    getEndPoint()['bankDetails'](id)
  );

  return response.data;
}

type MutateBankingDetailsResponse = {
  message: string;
};

async function mutateBankingDetailsItem(
  userId: number,
  type: 'put' | 'post',
  { id, ...data }: SetOptional<BankingDetailsItemResponse, 'id' | 'is_default'>
): Promise<MutateBankingDetailsResponse> {
  const requestUrl =
    type === 'post'
      ? getEndPoint()['addBankItem'](userId)
      : getEndPoint()['editBankDetailsItem'](userId, id as number);

  const response = await getHttpService('users')[
    type
  ]<MutateBankingDetailsResponse>(requestUrl, { ...data }, { url: requestUrl });

  return response.data;
}

export { getBankingDetails, mutateBankingDetailsItem };
