import { FunctionComponent } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/layout';
import { DateTime } from 'luxon';
import { notificationsApi, usersApi } from '../../api';
import { useHistory } from 'react-router-dom';
import { NOTIFICATIONS_NO_DIRECTS } from '../../utils/Constants';
import { useAuth } from '../../utils/AmplifyContext';

const flexContainerStyles = {
  gap: '14px'
} as const;
const titleContainerStyles = {
  gap: '10px'
} as const;
type NotificationItemProps = {
  id: number;
  secret_id: number;
  title: string;
  message: string;
  seen: boolean;
  notification: {
    id: number;
    name: string;
    code: string;
  };
  message_info: {
    secret_cover_image: string;
  };
  sent_date: string;
  onClose: () => {};
};
const NotificationItem: FunctionComponent<NotificationItemProps> = ({
  id,
  secret_id,
  title,
  message,
  seen,
  notification,
  message_info,
  sent_date,
  onClose
}) => {
  const history = useHistory();
  const { initialize } = useAuth();
  const notificationItemStyles = {
    borderBottom: '1px solid #EEEEEE',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.19px',
    backgroundColor: seen ? '#FFFFFF' : '#DDDDDD',
    '&:hover': {
      cursor: 'pointer'
    }
  };
  const navigate = () => {
    if (!NOTIFICATIONS_NO_DIRECTS.includes(notification.code)) {
      history.push(`/secrets/${secret_id}`);
    }
  };
  const notificationClicked = () => {
    if (!seen) {
      notificationsApi
        .post(`users/me/notifications/${id}/seen`, {})
        .then((data) => {
          initialize();
          navigate();
          onClose();
        });
    } else {
      navigate();
    }
  };
  return (
    <Box css={notificationItemStyles} onClick={notificationClicked}>
      <Box padding='12px 14px 12px 16px'>
        <Flex css={flexContainerStyles}>
          <Image
            borderRadius='full'
            boxSize='45px'
            objectFit='cover'
            minWidth='45px'
            border='1px solid #919191'
            src={message_info.secret_cover_image}
          />
          <Box>
            <Flex css={titleContainerStyles}>
              <Text
                c='#252525'
                fontWeight='bold'
                fontSize='12px'
                lineHeight='15px'
                textAlign='start'
              >
                {title}
              </Text>
              <Text>
                {DateTime.fromISO(sent_date.replace(' ', 'T')).toRelative()}
              </Text>
            </Flex>
            <Text textAlign='start'>{message}</Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default NotificationItem;
