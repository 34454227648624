import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getMySecrets } from '../../api/my-secrets';
import { getNotifications } from '../../api/notifications';
import NotificationItem from '../NotificationItem/NotificationItem';
import { NOTIFICATIONS_OFFSET, SECRETS_PAGE_SIZE } from '../../utils/Constants';
import InfiniteScroll from 'react-infinite-scroller';
import { useAuth } from '../../utils/AmplifyContext';
import { CurrentView } from '../SigninContainer/types';
import { notificationsApi } from '../../api';

const notificationsHeaderStyles = {
  padding: '16px',
  borderBottom: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'space-between'
};

const markAllAsReadStyles = {
  color: '#CC0099',
  fontSize: '10px',
  fontWeight: 'bold',
  letterSpacing: '0.15px',
  lineHeight: '12px',
  '&:hover': {
    cursor: 'pointer'
  }
} as const;
const notificationsListStyles = { overflow: 'auto', height: '440px' };
type NotificationsListProps = { onClose: () => void }
const NotificationsList: FunctionComponent<NotificationsListProps> = ({onClose}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const [currentPage, setCurrentPage] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const { initialize } = useAuth();
  const notificationsQuery = useQuery({
    queryFn: () => getNotifications(currentPage),
    queryKey: 'notifications'
  });
  const getMoreNotifications = (page = 0) => {
    setCurrentPage(page);
  };

  const loadNotificationsQuery = () => {
    notificationsQuery.refetch().then((response) => {
      setNotifications([...notifications, ...(response?.data?.items as any[])]);
      setTotal(response.data?.total as any);
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadNotificationsQuery();
    }
  }, [currentPage]);
  const markAllAsRead = () => {
    notificationsApi
      .post(`users/me/notifications/all_seen`, {})
      .then((data) => {
        initialize();
        onClose();
      });
  };
  return (
    <Box w='100%'>
      <Box css={notificationsHeaderStyles}>
        <Text
          c='rgba(0,0,0,0.85)'
          fontSize='12px'
          fontWeight='bold'
          letterSpacing='0.2px'
          lineHeight='15px'
        >
          {t('NOTIFICATIONS.TITLE')}
        </Text>
        <Text css={markAllAsReadStyles} onClick={markAllAsRead}>
          {t('NOTIFICATIONS.MARK_ALL_AS_READ')}
        </Text>
      </Box>
      <Box css={notificationsListStyles}>
        <InfiniteScroll
          pageStart={0}
          loadMore={getMoreNotifications}
          useWindow={false}
          hasMore={total > (currentPage + 1) * NOTIFICATIONS_OFFSET}
          initialLoad={false}
          loader={
            <div className='loader' key={0}>
              Loading ...
            </div>
          }
        >
          {notifications &&
            !!notifications.length &&
            notifications.map((notification) => (
              <NotificationItem {...notification} onClose={onClose}/>
            ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default NotificationsList;
