export const videoCardInitialState = {
  isPlaying: false,
  is_favourite: false,
  shared: false,
  showDetails: true
};
export const videoCardReducer = (state, action) => {
  switch (action.type) {
    case 'play':
      return { ...state, isPlaying: !state.isPlaying };
    case 'pause':
      return { ...state, isPlaying: false };
    case 'stop':
      return { ...state, isPlaying: false };
    case 'love':
      return { ...state, is_favourite: !state.is_favourite };
    case 'share':
      return { ...state, shared: !state.shared };
    case 'details':
      return { ...state, showDetails: !state.showDetails };
    default:
      throw new Error();
  }
};

export const filtersInitialState = {
  name: '',
  categories_ids: null,
  status: null,
  sub_categories_ids: [],
  partners_ids: [],
  sellers_ids: [],
  shares_prices: [],
  end_date: null,
  shares_sold_from: 0,
  shares_sold_to: 100,
  only_favourites: false,
  showSearchBar: false
};

export const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'search':
      return { ...state, name: action.payload, showSearchBar: true };
    case 'search_results':
      return { ...state, searchResults: action.payload };
    case 'status':
      return { ...state, status: action.payload };
    case 'category':
      // Reset all filters except search on change category
      const { name } = state;
      //only single category selection is supported
      let categories_ids = action.payload;
      if (
        state.categories_ids &&
        state.categories_ids.includes(action.payload[0])
      ) {
        categories_ids = [];
      }
      return { ...filtersInitialState, name, categories_ids: categories_ids };
    case 'subCategories':
      return { ...state, sub_categories_ids: action.payload };
    case 'partners':
      return { ...state, partners_ids: action.payload };
    case 'sellers':
      return { ...state, sellers_ids: action.payload };
    case 'prices':
      return { ...state, shares_prices: action.payload };
    case 'date':
      return { ...state, end_date: action.payload };
    case 'shares':
      return {
        ...state,
        shares_sold_from: action.payload[0],
        shares_sold_to: action.payload[1]
      };
    case 'sharesFrom':
      return { ...state, shares_sold_from: action.payload };
    case 'sharesTo':
      return { ...state, shares_sold_to: action.payload };
    case 'only_favourites':
      return { ...state, only_favourites: action.payload };
    case 'hide_search':
      return { ...state, showSearchBar: false, name: '', searchResults: 0 };
    default:
      throw new Error();
  }
};

export const searchResultsInitialState = {
  searchResults: 0
};

export const searchReducer = (state, action) => {
  switch (action.type) {
    case 'search_results':
      return { ...state, searchResults: action.payload };
    default:
      throw new Error();
  }
};

export const videoPlayInitialState = {
  secretId: null
};
export const videoPlayReducer = (state, action) => {
  switch (action.type) {
    case 'play':
      return { ...state, secretId: action.payload };
    default:
      throw new Error();
  }
};
