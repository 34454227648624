import React, { FunctionComponent } from 'react';
import visaIcon from '../../assets/icons/visa.svg';
import { Box, Image } from '@chakra-ui/react';
import { Flex, HStack, Text } from '@chakra-ui/layout';
import { EditIcon } from '@chakra-ui/icons';

type SavedCreditCardProps = {
  cardNumber: string;
  editCard?: () => void;
  showEdit: boolean;
};

const SavedCreditCard: FunctionComponent<SavedCreditCardProps> = ({
  cardNumber,
  editCard,
  showEdit
}) => {
  return (
    <Box
      maxWidth='445px'
      border='1px solid #CCCCCC'
      borderRadius='8px'
      padding='8px'
    >
      <Flex justifyContent='space-between'>
        <Image src={visaIcon} />
        {showEdit && (
          <EditIcon
            onClick={editCard}
            _hover={{
              cursor: 'pointer'
            }}
          />
        )}
      </Flex>
      <Box mt='16px'>
        <Text textAlign='start'>{cardNumber}</Text>
      </Box>
    </Box>
  );
};

export default SavedCreditCard;
