import { Suspense, lazy } from 'react';
import { Spinner } from '@chakra-ui/react';
import LineSeparator from 'components/LineSeperator';
import { Redirect, Route, Switch } from 'react-router-dom';
import AddPayment from '../pages/AddPayment/AddPayment';
import MobileCheckout from 'pages/MobileCheckout/MobileCheckout';

const DashboardPage = lazy(
  () =>
    import(/* webpackChunkName: "DashboardPage" */ 'pages/Dashboard/Dashboard')
);
const SecretsView = lazy(
  () =>
    import(
      /* webpackChunkName: "SecretsView" */ 'pages/Dashboard/components/Secrets'
    )
);

const FavouritesView = lazy(
  () =>
    import(
      /* webpackChunkName: "FavouritesView" */ 'pages/Dashboard/components/Favourites'
    )
);
const SellSecret = lazy(
  () =>
    import(/* webpackChunkName: "SellSecret" */ 'pages/SellSecret/SellSecret')
);
const Overview = lazy(
  () => import(/* webpackChunkName: "Overview" */ 'pages/UserProfile/OverView')
);

const MyTransactions = lazy(
  () =>
    import(
      /* webpackChunkName: "MyTransactions" */ 'pages/MyTransactions/MyTransactions'
    )
);

const SecretDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "MySecrets" */ 'pages/SecretDetails/SecretDetails'
    )
);
const MySecrets = lazy(
  () => import(/* webpackChunkName: "MySecrets" */ 'pages/MySecrets/MySecrets')
);
const UserProfiles = lazy(
  () =>
    import(
      /* webpackChunkName: "UserProfile" */ 'pages/UserProfile/UserProfile'
    )
);

const Privacy = lazy(
  () => import(/* webpackChunkName: "UserProfile" */ 'pages/Privacy/Privacy')
);
const TermsOfUse = lazy(
  () =>
    import(/* webpackChunkName: "UserProfile" */ 'pages/TermsOfUse/TermsOfUse')
);
const ContactUs = lazy(
  () =>
    import(
      /* webpackChunkName: "UserProfile" */ 'pages/ContactUs/ContactUsWrapper'
    )
);
const AboutUs = lazy(
  () => import(/* webpackChunkName: "UserProfile" */ 'pages/AboutUs/Aboutus')
);
const BankingDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "BankingDetails" */ 'pages/UserProfile/BankingDetails'
    )
);
const Ratings = lazy(
  () =>
    import(
      /* webpackChunkName: "Ratings" */ 'pages/UserProfile/ReviewsAndRatings'
    )
);
const EditUser = lazy(
  () => import(/* webpackChunkName: "EditUser" */ 'pages/UserProfile/EditUser')
);

const Checkout = lazy(
  () => import(/* webpackChunkName: "Checkout" */ 'pages/Checkout/Checkout')
);

const Payment = lazy(
  () => import(/* webpackChunkName: "Payment" */ 'pages/Payment/Payment')
);

const PaymentMethods = lazy(
  () =>
    import(/* webpackChunkName: "Payment" */ 'pages/UserProfile/PaymentMethods')
);

const ChangePassword = lazy(
  () =>
    import(/* webpackChunkName: "Payment" */ 'pages/UserProfile/ChangePassword')
);

const Settings = lazy(
  () => import(/* webpackChunkName: "Payment" */ 'pages/UserProfile/Settings')
);

export const ROUTES = {
  DISCOVER: 'discover',
  CHECKOUT: 'checkout',
  FAVORITES: 'favorites',
  SELL: 'sell',
  PROFILE: 'profile',
  MY_SECRETS: 'my-secrets',
  MY_TRANSACTIONS: 'my-transactions',
  SECRET_DETAILS: 'secrets/:id',
  OVERVIEW: 'profile/overview',
  RATINGS_AND_REVIEWS: 'profile/ratings-and-reviews',
  BANKING_DETAILS: 'profile/banking-details',
  EDIT: 'profile/edit',
  PAYMENT_METHODS: 'profile/payment-methods',
  SETTINGS: 'profile/settings',
  CHANGE_PASSWORD: 'profile/change-password',
  PAYMENT: 'payment',
  PRIVACY: 'privacy',
  CONTACT_US: 'contact-us',
  ABOUT_US: 'about-us',
  TERMS_OF_USE: 'terms-of-use',
  ADD_PAYMENT: 'add-payment',
  MOBILE_CHECKOUT: 'mobile-checkout'
} as const;

export const getRelativePath = (path: keyof typeof ROUTES) =>
  `/${ROUTES[path]}`;

export function ProfileRoutes() {
  return (
    <Switch>
      <Route path={getRelativePath('OVERVIEW')}>
        <Overview />
      </Route>
      <Route path={getRelativePath('RATINGS_AND_REVIEWS')}>
        <Ratings />
      </Route>
      <Route path={getRelativePath('BANKING_DETAILS')}>
        <BankingDetails />
      </Route>
      <Route path={getRelativePath('EDIT')}>
        <EditUser />
      </Route>
      <Route path={getRelativePath('PAYMENT_METHODS')}>
        <PaymentMethods />
      </Route>
      <Route path={getRelativePath('SETTINGS')}>
        <Settings />
      </Route>
      <Route path={getRelativePath('CHANGE_PASSWORD')}>
        <ChangePassword />
      </Route>
      <Redirect
        from={getRelativePath('PROFILE')}
        to={getRelativePath('OVERVIEW')}
      />
    </Switch>
  );
}

export function DashboardRoutes(props: { parentRef: any }) {
  return (
    <Switch>
      <Route path={getRelativePath('DISCOVER')}>
        <SecretsView parentRef={props.parentRef} />
      </Route>
      <Route path={getRelativePath('FAVORITES')}>
        <FavouritesView parentRef={props.parentRef} />
      </Route>
      <Route path={getRelativePath('PROFILE')} component={UserProfiles} />
      <Route path={getRelativePath('PRIVACY')} component={Privacy} />
      <Route path={getRelativePath('TERMS_OF_USE')} component={TermsOfUse} />
      <Route path={getRelativePath('CONTACT_US')} component={ContactUs} />
      <Route path={getRelativePath('ABOUT_US')} component={AboutUs} />
      <Route path={getRelativePath('MY_SECRETS')} component={MySecrets} />
      <Route
        path={getRelativePath('MY_TRANSACTIONS')}
        component={MyTransactions}
      />
      <Route
        path={getRelativePath('SECRET_DETAILS')}
        component={SecretDetails}
      />
      <Redirect from='/' to={getRelativePath('DISCOVER')} />
    </Switch>
  );
}

function Routes() {
  return (
    <Suspense fallback={<Spinner position={'absolute'} inset={0} />}>
      <LineSeparator />
      <Switch>
        <Route path={getRelativePath('SELL')} component={SellSecret} />
        <Route path={getRelativePath('CHECKOUT')} component={Checkout} />
        <Route path={getRelativePath('PAYMENT')} component={Payment} />;
        <Route path={getRelativePath('ADD_PAYMENT')} component={AddPayment} />;
        <Route
          path={getRelativePath('MOBILE_CHECKOUT')}
          component={MobileCheckout}
        />
        <Route path='/' component={DashboardPage} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
