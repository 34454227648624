import { Field, Form, Formik } from 'formik';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Box, Button, HStack, Image, Stack, Text } from '@chakra-ui/react';
import payPalIcon from '../../assets/images/paypal.svg';
import visaIcon from '../../assets/icons/visa.svg';
import masterCardIcon from '../../assets/icons/master-card.svg';
import maestroIcon from '../../assets/icons/maestro.svg';
import americanExpressIcon from '../../assets/icons/american-express.svg';
import Input from '../Input/Input';
import emailIcon from '../../assets/icons/email_icon.svg';
import {
  CREDIT_CARD_YEARS_INTERVAL,
  EXPIRY_DATE_MONTHS
} from '../../utils/Constants';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { usePaymentInputs } from 'react-payment-inputs';
import { CurrentView } from '../SigninContainer/types';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  payment_method: Yup.string().required('Please choose payment method'),
  number: Yup.number().when('payment_method', {
    is: 'credit',
    then: Yup.number().min(16).required('Enter Card Number')
  }),
  name: Yup.string().when('payment_method', {
    is: 'credit',
    then: Yup.string().required('Enter Card Holder Name')
  }),
  expiry_month: Yup.string().when('payment_method', {
    is: 'credit',
    then: Yup.string().min(2).required('Enter expiry Month')
  }),
  expiry_year: Yup.string().when('payment_method', {
    is: 'credit',
    then: Yup.string().min(4).required('Enter expiry Year')
  }),
  security_code: Yup.number().when('payment_method', {
    is: 'credit',
    then: Yup.number().min(3).required('Enter CVC code')
  })
});

const initialValues = {
  number: '',
  name: '',
  expiry_month: '',
  expiry_year: '',
  security_code: '',
  payment_method: '',
  save: false
};

const backDropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute'
  }
}));

const radioStyles = makeStyles({
  root: {
    color: '#802081',
    lineHeight: '14px',
    fontSize: '14px'
  },
  label: {}
});

const paymentMethodContainer = {
  border: '1px solid #CCCCCC',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  padding: '12px 12px 9px 12px'
};

const labelStyles = {
  textAlign: 'start',
  fontFamily: 'Lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '17px',
  color: '#9E9E9E',
  minHeight: '17px'
} as const;

const checkboxStyles = makeStyles({
  root: {
    fontFamily: 'Lato',
    color: '#9E9E9E',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0',
    textAlign: 'start'
  }
});

const payNowStyles = {
  borderRadius: '6px',
  backgroundColor: '#802081',
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '0.53px',
  lineHeight: '19px',
  marginTop: '23px',
  width: '100%',
  float: 'left'
} as const;

type PaymentFormProps = {
  onSubmit: (values: any, formikBag: any) => void;
  submitLabel: string;
  showSave?: boolean;
};

const PaymentForm: FunctionComponent<PaymentFormProps> = ({
  onSubmit,
  submitLabel,
  showSave
}) => {
  const backDropClasses = backDropStyles();
  const radioStylesClasses = radioStyles();
  const checkboxClasses = checkboxStyles();
  const currentYear = new Date().getFullYear();
  const { getCardNumberProps, getCVCProps } = usePaymentInputs();
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        setSubmitting,
        touched,
        errors
      }) => (
        <Form>
          <Backdrop className={backDropClasses.backdrop} open={isSubmitting}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <RadioGroup name='payment_method'>
            <Stack spacing='12px'>
              <Box css={paymentMethodContainer}>
                <HStack spacing='3px'>
                  <FormControlLabel
                    classes={{ ...radioStylesClasses }}
                    key='paypal'
                    value='paypal'
                    control={<Radio />}
                    label=''
                    onChange={() => {
                      setFieldValue('payment_method', 'paypal');
                    }}
                  />
                  <Image src={payPalIcon} />
                  {/*<Grid container spacing={1}>*/}
                  {/*  <Grid item xs={12}>*/}
                  {/*    <Field name='name'>*/}
                  {/*      {({ form, field }: any) => (*/}
                  {/*        <Input*/}
                  {/*          form={form}*/}
                  {/*          field={field}*/}
                  {/*          label={t(*/}
                  {/*            'USER_PROFILE.PAYMENT_METHODS.ACCOUNT_NICKNAME'*/}
                  {/*          )}*/}
                  {/*          fullWidth*/}
                  {/*          type='text'*/}
                  {/*          payment*/}
                  {/*        />*/}
                  {/*      )}*/}
                  {/*    </Field>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                </HStack>
                {values.payment_method === 'paypal' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box
                        c='9E9E9E'
                        fontSize='16px'
                        lineHeight='21px'
                        letterSpacing='0'
                        textAlign='start'
                        fontFamily='lato'
                      >
                        {showSave && (
                          <Field name='save' fullWidth>
                            {({ form, field }: { form: any; field: any }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    classes={{ ...checkboxClasses }}
                                    style={{ textAlign: 'start' }}
                                    checked={values.save}
                                    onChange={() => {
                                      form.setFieldValue('save', !values.save);
                                    }}
                                    name='save'
                                    color='primary'
                                  />
                                }
                                label={<Box>{t('CHECKOUT.SAVE_PAYPAL')}</Box>}
                              />
                            )}
                          </Field>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Box css={paymentMethodContainer}>
                <HStack spacing='3px'>
                  <FormControlLabel
                    classes={{ ...radioStylesClasses }}
                    key='credit'
                    value='credit'
                    control={<Radio />}
                    label=''
                    onChange={() => {
                      setFieldValue('payment_method', 'credit');
                    }}
                  />
                  <HStack spacing='20px' overflow='hidden'>
                    <Image src={visaIcon} />
                    <Image src={masterCardIcon} />
                    <Image src={maestroIcon} />
                    <Image src={americanExpressIcon} />
                  </HStack>
                </HStack>
                {values.payment_method === 'credit' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field name='number'>
                        {({ form, field }: any) => (
                          <Input
                            form={form}
                            field={field}
                            label={t('CHECKOUT.CARD_NUMBER')}
                            fullWidth
                            {...getCardNumberProps({
                              onBlur: field.onBlur,
                              onChange: field.onChange
                            })}
                            payment
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name='name'
                        label={t('CHECKOUT.CARD_HOLDER_NAME')}
                        icon={emailIcon}
                        detailedType='text'
                        fullWidth
                        component={Input}
                        payment
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={6}
                      style={{
                        padding: '4px',
                        alignContent: 'baseline'
                      }}
                    >
                      <Text css={labelStyles}>{t('CHECKOUT.EXPIRY_DATE')}</Text>
                      <Grid item xs={6}>
                        <Field name='expiry_month'>
                          {({ form, field }: any) => (
                            <Input
                              form={form}
                              type='dropdown'
                              placeholder='MM'
                              options={EXPIRY_DATE_MONTHS}
                              field={field}
                              label=''
                              fullWidth
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '8px' }}>
                        <Field name='expiry_year'>
                          {({ form, field }: any) => (
                            <Input
                              form={form}
                              type='dropdown'
                              placeholder='YY'
                              options={_.range(
                                currentYear,
                                currentYear + CREDIT_CARD_YEARS_INTERVAL
                              )}
                              field={field}
                              label=''
                              fullWidth
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name='security_code'>
                        {({ form, field }: any) => (
                          <Input
                            form={form}
                            field={field}
                            fullWidth
                            label={t('CHECKOUT.CVC_CODE')}
                            {...getCVCProps({
                              onBlur: field.onBlur,
                              onChange: field.onChange
                            })}
                            payment
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        c='9E9E9E'
                        fontSize='16px'
                        lineHeight='21px'
                        letterSpacing='0'
                        textAlign='start'
                        fontFamily='lato'
                      >
                        {showSave && (
                          <Field name='save' fullWidth>
                            {({ form, field }: { form: any; field: any }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    classes={{ ...checkboxClasses }}
                                    style={{ textAlign: 'start' }}
                                    checked={values.save}
                                    onChange={() => {
                                      form.setFieldValue('save', !values.save);
                                    }}
                                    name='save'
                                    color='primary'
                                  />
                                }
                                label={<Box>{t('CHECKOUT.SAVE_MY_CARD')}</Box>}
                              />
                            )}
                          </Field>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Stack>
          </RadioGroup>
          <FormHelperText>
            {!!touched['payment_method'] && errors['payment_method']}
          </FormHelperText>
          <Button
            css={payNowStyles}
            disabled={isSubmitting || !isValid}
            type='submit'
          >
            {submitLabel}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
