import { getHttpService } from '../httpService';
import { NOTIFICATIONS_OFFSET } from '../../utils/Constants';

export type NotificationItemResponse = {
  id: number;
  name: string;
  sale_status: string;
  flow_status: string;
  user: {
    id: number;
    name: string;
    profile_photo: string;
  };
  description: string;
  share_price: number;
  price: number;
  sold_shares: number;
  end_date: string;
  partners: Array<{
    id: number;
    name: string;
    profile_photo: {
      path: string;
      url: string;
    };
  }>;
  trailer: {
    path: string;
    cover: string;
  };
  actual: {
    path: string;
    cover: string;
  };
  is_favourite: boolean;
  rate: number;
  sub_category: any;
};
export type NotificationsResponse = {
  items: Array<NotificationItemResponse>;
  total: number;
};

async function getNotifications(page: number): Promise<NotificationsResponse> {
  const params = new URLSearchParams();
  params.append('limit', NOTIFICATIONS_OFFSET + '');
  params.append('offset', page * NOTIFICATIONS_OFFSET + '');
  const response = await getHttpService(
    'notifications'
  ).get<NotificationsResponse>(`users/me/notifications`, {
    params
  });
  return response.data;
}

export { getNotifications };
