import { Image, Text, VStack, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const linkStyles = {
  height: '17px',
  minWidth: '75px',
  opacity: '0.6',
  fontFamily: 'lato',
  fontSize: '14px',
  letterSpacing: '0.22px',
  lineHeight: '17px',
  fontWeight: 'bold'
};

const NavItem = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActive = location.pathname === props.link;

  return (
    <Link
      as={NavLink}
      exact
      to={props.link}
      _focus={{ outline: 'none' }}
    >
      <VStack>
        <Image
          h={'28px'}
          w={'28px'}
          src={isActive ? props.selectedIcon : props.icon}
        />
        <Text sx={linkStyles} color={isActive ? 'brand.500' : '#494949'}>
          {t(props.label)}
        </Text>
      </VStack>
    </Link>
  );
};

export default NavItem;
