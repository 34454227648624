import { Box, Flex, Text } from '@chakra-ui/react';
import SecretsVilleDivider from '../../../components/SecretsVilleDivider';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { HStack } from '@chakra-ui/layout';
import { LockIcon } from '@chakra-ui/icons';

const checkoutSummaryStyles = {
  padding: '15px 26px 14px 26px',
  backgroundColor: '#F3F3F3',
  fontFamily: 'Lato',
  color: 'rgba(0,0,0,0.85)'
};

type CheckoutSummaryProps = { numberOfShares: number; sharePrice: number };
export const CheckoutSummary: FunctionComponent<CheckoutSummaryProps> = ({
  numberOfShares,
  sharePrice
}) => {
  const { t } = useTranslation();
  return (
    <Box padding='13px 15px 15px 10px'>
      <Box css={checkoutSummaryStyles}>
        <Text
          textAlign='start'
          fontSize='17px'
          fontWeight='bold'
          letterSpacing='0.26px'
          lineHeight='21px'
        >
          {t('CHECKOUT.PAYMENT_METHOD')}
        </Text>
        <HStack mt='7px'>
          <LockIcon />
          <Text
            textAlign='start'
            color='#4E4E4E'
            fontSize='13px'
            letterSpacing='0.2px'
            lineHeight='16px'
          >
            {t('CHECKOUT.ALL_TRANSACTIONS_ARE_SAFE')}
          </Text>
        </HStack>
        <Box mt='15px'>
          <SecretsVilleDivider />
        </Box>
        <Box mt='15px'>
          <Flex justifyContent='space-between'>
            <Text fontSize='17px' letterSpacing='0.26px' lineHeight='21px'>
              {t('CHECKOUT.NUMBER_OF_SHARES')}
            </Text>
            <Text
              fontSize='19px'
              color='#252525'
              letterSpacing='0.64px'
              lineHeight='23px'
            >
              {numberOfShares}
            </Text>
          </Flex>
          {/*<Flex justifyContent='space-between' mt='8px'>*/}
          {/*  <Text fontSize='17px' letterSpacing='0.26px' lineHeight='21px'>*/}
          {/*    {t('CHECKOUT.SHARE_PRICE')}*/}
          {/*  </Text>*/}
          {/*  <Text*/}
          {/*    fontSize='19px'*/}
          {/*    color='#252525'*/}
          {/*    letterSpacing='0.64px'*/}
          {/*    lineHeight='23px'*/}
          {/*  >*/}
          {/*    $ {sharePrice}*/}
          {/*  </Text>*/}
          {/*</Flex>*/}
        </Box>
        <Box mt='15px'>
          <SecretsVilleDivider />
        </Box>
        <Box mt='15px'>
          <Flex justifyContent='space-between'>
            <Text fontSize='17px' letterSpacing='0.26px' lineHeight='21px'>
              {t('CHECKOUT.ORDER_TOTAL')}
            </Text>
            <Text
              fontSize='24px'
              color='#252525'
              fontWeight='bold'
              letterSpacing='0.8px'
              lineHeight='29px'
            >
              ${numberOfShares * sharePrice}
            </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
