import chakraTheme from './theme/theme';
import { css, Global } from '@emotion/react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
  AuthenticationModalContext,
  FiltersContext,
  SearchContext,
  VideoPlayContext
} from './pages/Dashboard/contexts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthenticationModal from './components/AuthenticationModal';
import NavBar from './components/NavBar';
import SecretsVilleDivider from './components/SecretsVilleDivider';
import Routes from './routes/routes';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { useMemo, useReducer, useRef } from 'react';
import {
  filtersInitialState,
  filtersReducer,
  searchReducer,
  searchResultsInitialState,
  videoPlayInitialState,
  videoPlayReducer
} from './pages/Dashboard/reducers';
import { useAuth } from './utils/AmplifyContext';
import i18n from 'i18next';
import createThem from './theme/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: 'transparent!important',
        '&hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiButtonBase: {
      root: {
        color: '#802081!important'
      }
    },
    MuiTypography: {
      root: {
        fontFamily: 'lato!important'
      }
    }
  }
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

function ExtendedApp() {
  const { user } = useAuth();
  const isRtl = user?.language && user.language === 'ARABIC';
  if (isRtl) {
    document.body.style.direction = 'rtl';
    document.body.classList.add('page-rtl');
    i18n.changeLanguage('ar');
  }
  const [state, dispatch] = useReducer(
    filtersReducer,
    filtersInitialState,
    (initialState) => initialState
  );

  const [searchState, dipatchSearch] = useReducer(
    searchReducer,
    searchResultsInitialState,
    (initialState) => initialState
  );
  const [videoPlayState, dispatchVideoPlay] = useReducer(
    videoPlayReducer,
    videoPlayInitialState,
    (initialState) => initialState
  );
  const childRef = useRef();
  const openAuthenticationDialog = () => {
    childRef.current.openModal();
  };

  const filtersContextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  const searchContextValue = useMemo(() => {
    return { searchState, dipatchSearch };
  }, [searchState, dipatchSearch]);

  const videoPlayContext = useMemo(() => {
    return { videoPlayState, dispatchVideoPlay };
  }, [videoPlayState, dispatchVideoPlay]);
  return (
    <ChakraProvider theme={createThem(isRtl ? 'rtl' : 'ltr')}>
      <Global styles={GlobalStyles} />
      <MuiThemeProvider theme={theme}>
        <AuthenticationModalContext.Provider value={openAuthenticationDialog}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <FiltersContext.Provider value={filtersContextValue}>
                <SearchContext.Provider value={searchContextValue}>
                  <VideoPlayContext.Provider value={videoPlayContext}>
                    <div className='App'>
                      <AuthenticationModal ref={childRef} />
                      <NavBar />
                      <SecretsVilleDivider />
                      <Routes />
                      <ToastContainer hideProgressBar theme='colored' />
                    </div>
                  </VideoPlayContext.Provider>
                </SearchContext.Provider>
              </FiltersContext.Provider>
            </Router>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AuthenticationModalContext.Provider>
      </MuiThemeProvider>
    </ChakraProvider>
  );
}

export default ExtendedApp;
