import { SECRETS_PAGE_SIZE } from './Constants';
import { DateTime } from 'luxon';

//status is passed only from my secrets page
export const getSecretsParams = (
  filters,
  pageOffset = 0,
  status = null,
  userId = 0
) => {
  const filtersParamas = [];
  if (!status) {
    for (let filter in filters) {
      if (filters[filter]) {
        if (
          filter === 'showSearchBar' ||
          filter === 'searchResults' ||
          (filter === 'shares_sold_to' && filters[filter] === 100) ||
          (filter === 'categories_ids' &&
            (filters[filter].includes('ANY') || filters[filter].includes('0')))
        ) {
          continue;
        }
        if (Array.isArray(filters[filter])) {
          if (filters[filter].length) {
            for (let element in filters[filter]) {
              if (typeof filters[filter][element] === 'object') {
                filtersParamas.push([filter, filters[filter][element].id]);
              } else {
                filtersParamas.push([filter, filters[filter][element]]);
              }
            }
          }
        } else {
          if (filters[filter] === 'ANY' || filters[filter] === '0') {
          } else {
            if (filter === 'name') {
              filtersParamas.push(['general_search', filters[filter]]);
            } else {
              if (filter === 'end_date') {
                filtersParamas.push([
                  'end_date',
                  filters[filter].toISOString()
                ]);
              } else {
                filtersParamas.push([filter, filters[filter]]);
              }
            }
          }
        }
      }
    }
  }
  const params = new URLSearchParams(filtersParamas);
  params.append('limit', SECRETS_PAGE_SIZE);
  params.append('offset', pageOffset * SECRETS_PAGE_SIZE);
  if (userId) {
    params.append('sellers_ids', [userId]);
  }
  if (status) {
    params.append('status', status);
  }
  return params;
};

export const formatBytes = (a, b = 2, k = 1024) => {
  let d = Math.floor(Math.log(a) / Math.log(k));
  return 0 === a
    ? '0 Bytes'
    : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
};

export const getBackgroundImage = (secret) => {
  if (secret.actual && secret.actual.cover) {
    return secret.actual.cover;
  }
  if (secret.trailer && secret.trailer.cover) {
    return secret.trailer.cover;
  }
  return 'https://images.pexels.com/photos/38537/woodland-road-falling-leaf-natural-38537.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260';
};

export const getSecretVideo = (secret) => {
  if (secret.actual && secret.actual.url) {
    return secret.actual.url;
  }
  if (secret.trailer && secret.trailer.url) {
    return secret.trailer.url;
  }
};

export const getSecretAssetType = (secret) => {
  if (secret.actual && secret.actual.url) {
    if (secret.actual.url.includes('mp4')) {
      return 'video';
    } else {
      if (
        secret.actual.url.includes('mp3') ||
        secret.actual.url.includes('wav')
      ) {
        return 'audio';
      } else {
        return 'download';
      }
    }
  }
  if (secret.trailer && secret.trailer.url) {
    if (secret.trailer.url.includes('mp4')) {
      return 'video';
    } else {
      if (
        secret.actual.url.includes('mp3') ||
        secret.actual.url.includes('wav')
      ) {
        return 'audio';
      } else {
        return 'download';
      }
    }
  }
};

export const getExpiryDate = (expiryYear, expiryMonth) => {
  return `${expiryYear}-${expiryMonth}`;
};
