import axios from 'axios';
import { Auth } from 'aws-amplify';

export type ServiceType = 'secrets' | 'users' | 'payments' | 'notifications';
export const getBaseUrl = (
  serviceType: ServiceType
): `${string}${ServiceType}${string}` =>
  `https://api.secretsville.com/${serviceType}/api/v1`;

const httpService = axios.create({
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

httpService.interceptors.request.use(async (request) => {
  try {
    const authData = await Auth.currentSession();

    request.headers['Authorization'] = `Bearer ${authData
      .getAccessToken()
      .getJwtToken()}`;

    return request;
  } catch {
    return request;
  }
});

const getHttpService = (serviceType: ServiceType) => {
  httpService.defaults.baseURL = getBaseUrl(serviceType);

  return httpService;
};

export { getHttpService };
