import { extendTheme } from '@chakra-ui/react';

const createTheme = (direction: string = 'ltr') => {
  const theme = extendTheme({
    direction: direction,
    colors: {
      brand: {
        50: '#ffe2fe',
        100: '#ffb1ee',
        200: '#ff7fe0',
        300: '#ff4cd2',
        400: '#ff1ac6',
        500: '#802081',
        600: '#b40086',
        700: '#810061',
        800: '#50003a',
        900: '#1f0016'
      },
      gray: {
        50: '#faf9fa',
        100: '#efedef',
        200: '#e4dfe3',
        300: '#d8d1d6',
        400: '#cbc1c8',
        500: '#bcafb9',
        600: '#ab9ba7',
        700: '#978492',
        800: '#7d6577',
        900: '#4b3a47'
      }
    }
  });
  return theme;
};

export default createTheme;
