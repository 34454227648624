import { FunctionComponent, useState } from 'react';
import { Box, Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import backIcon from '../../assets/icons/back_black.svg';
import { useTranslation } from 'react-i18next';
import { CurrentView } from '../SigninContainer/types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import Input from '../Input/Input';
import emailIcon from '../../assets/icons/email_icon.svg';
import { useAuth } from '../../utils/AmplifyContext';
import { Auth } from 'aws-amplify';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

const signinEmailStyles = {
  color: '#494949',
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: 500,
  letterSpacing: '0',
  lineHeight: '19px'
} as const;
type SigninEmailProps = {
  changeView: (currentView: CurrentView, email?: string) => void;
  onClose: () => void;
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Field is Required').email('Invalid email'),
  password: Yup.string()
    .required('Field is Required')
    .min(8, 'Invalid password(has to be at least 8 characters)')
});

const SignInButtonStyles = {
  marginTop: '42px',
  width: '100%',
  backgroundColor: '#802081',
  borderRadius: '23.5px',
  color: '#FFFFFF',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  fontFamily: 'lato',
  height: '48px',
  padding: '11px 29px 11px 29px',
  boxSizing: 'border-box',
  fontWeight: 'bold'
} as const;

const CreateButtonStyles = {
  marginTop: '20px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  borderRadius: '25px',
  color: '#802081',
  border: '1px solid #802081',
  fontSize: '17px',
  letterSpacing: '0',
  lineHeight: '21px',
  fontFamily: 'lato',
  height: '49px',
  padding: '11px 29px 11px 29px',
  boxSizing: 'border-box',
  fontWeight: 'normal'
} as const;
const SigninEmail: FunctionComponent<SigninEmailProps> = ({
  changeView,
  onClose
}) => {
  const user = useAuth();
  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onSubmit = async (values: any, formikBag: any) => {
    if (!loading) {
      const { setSubmitting } = formikBag;
      setLoading(true);
      setSubmitting(true);
      user.login(values.email, values.password).then(
        () => {
          setLoading(false);
          setSubmitting(false);
          onClose();
        },
        async (error) => {
          if (error.name === 'UserNotConfirmedException') {
            Auth.resendSignUp(values.email);
            changeView('verify', values.email);
            return;
          } else if (error.message === 'Invalid credentials') {
            Auth.resendSignUp(values.email);
            changeView('verify', values.email);
          } else {
            setError(error?.message ?? 'Something went wrong');
          }
          setLoading(false);
          setSubmitting(false);
        }
      );
    }
  };
  return (
    <Box mt='10px'>
      <Flex onClick={() => changeView('initial')}>
        <Image src={backIcon} _hover={{ cursor: 'pointer' }} />
        <Text css={signinEmailStyles} mr='36px' ml='36px'>
          {t('AUTHENTICATION.SIGN_IN_USING_EMAIL')}
        </Text>
      </Flex>
      <Box mt='51px'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Grid container spacing={1}>
                {error && (
                  <Grid item xs={12}>
                    <Alert status='error'>
                      <AlertIcon />
                      <AlertTitle>{error}</AlertTitle>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    name='email'
                    label={t('AUTHENTICATION.EMAIL_ADDRESS')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='email'
                    fullWidth
                    component={Input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='password'
                    label={t('AUTHENTICATION.PASSWORD')}
                    icon={emailIcon}
                    type='authentication'
                    fullWidth
                    component={Input}
                    detailedType='password'
                  />
                </Grid>
              </Grid>
              <Flex justifyContent='flex-end'>
                <Text
                  color='#802081'
                  fontFamily='Lato'
                  fontSize='14px'
                  _hover={{ cursor: 'pointer' }}
                  letterSpacing='0'
                  onClick={() => changeView('forgot-password')}
                  lineHeight='17px'
                >
                  {t('AUTHENTICATION.FORGOT')}
                </Text>
              </Flex>
              <Button
                css={SignInButtonStyles}
                isDisabled={isSubmitting || !isValid}
                disabled={isSubmitting || !isValid}
                type='submit'
                isLoading={isSubmitting}
              >
                {t('AUTHENTICATION.SIGN_IN')}
              </Button>
            </Form>
          )}
        </Formik>
        <Button css={CreateButtonStyles} onClick={() => changeView('signup')}>
          {t('AUTHENTICATION.CREATE_NEW_ACCOUNT')}
        </Button>
      </Box>
    </Box>
  );
};

export default SigninEmail;
