import { CurrentView } from '../SigninContainer/types';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Box, Button, Flex, Image, Text, Link } from '@chakra-ui/react';
import backIcon from '../../assets/icons/back_black.svg';
import { useTranslation } from 'react-i18next';
import facebookIcon from '../../assets/icons/facebook.svg';
import googleIcon from '../../assets/icons/google.svg';
import MidLineText from '../MidLineText';
import { Field, Form, Formik } from 'formik';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles
} from '@material-ui/core';
import emailIcon from '../../assets/icons/email_icon.svg';
import Input from '../Input/Input';
import * as Yup from 'yup';
import { useAuth } from '../../utils/AmplifyContext';
import { ROUTES } from '../../routes/routes';
import { Auth } from 'aws-amplify';

import i18n from 'i18next';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

type SignUpProps = {
  changeView: (
    currentView: CurrentView,
    email?: string,
    name?: string,
    password?: string
  ) => void;
  onClose: () => void;
};

const createAccountStyles = {
  color: '#494949',
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: 500,
  letterSpacing: '0',
  lineHeight: '19px'
} as const;

const facebookButtonStyles = {
  backgroundColor: '#FFFFFF',
  borderRadius: '24px',
  color: '#888888',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  fontFamily: 'Lato',
  justifyContent: 'flex-start',
  height: '48px',
  padding: '10px 10px 11px 24px',
  minWidth: '143px'
};

const linkStyles = {
  color: '#802081'
};

const SignUpButtonStyles = {
  marginTop: '42px',
  width: '100%',
  backgroundColor: '#802081',
  borderRadius: '23.5px',
  color: '#FFFFFF',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  fontFamily: 'lato',
  height: '48px',
  padding: '11px 29px 11px 29px',
  boxSizing: 'border-box',
  fontWeight: 'bold'
} as const;

const initialValues = {
  name: '',
  email: '',
  password: '',
  save: false
};

const checkboxStyles = makeStyles({
  root: {
    fontFamily: 'Lato',
    color: '#9E9E9E',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0',
    textAlign: 'start'
  }
});

//TODO: match tow words

const SignUp: FunctionComponent<SignUpProps> = ({ changeView, onClose }) => {
  const { t } = useTranslation();

  const user = useAuth();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Invalid name'),
        email: Yup.string().email().required('Invalid email'),
        password: Yup.string()
          .min(8, t('AUTHENTICATION.INVALID_PASSWORD'))
          .required(t('AUTHENTICATION.INVALID_PASSWORD'))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
          ),
        save: Yup.boolean().oneOf(
          [true],
          'You must agree to the terms and conditions'
        )
      }),
    [t]
  );
  const [error, setError] = useState<null | string>(null);
  const checkboxClasses = checkboxStyles();
  const googleSignIn = () => {
    Auth.federatedSignIn({
      provider: 'Google'
    } as any).then(async (cred) => {});
  };
  const facebookSignIn = () => {
    Auth.federatedSignIn({
      provider: 'Facebook'
    } as any).then((cred) => {
      return Auth.currentAuthenticatedUser();
    });
  };
  const onSubmit = async (values: any, formikBag: any) => {
    const { setSubmitting } = formikBag;
    setSubmitting(true);
    const [name, familyName] = values.name.split(' ');
    try {
      const signUpResponse = await user.register(
        values.email,
        values.password,
        name,
        familyName
      );
      setSubmitting(false);
      changeView('verify', values.email, values.name, values.password);
    } catch (e: any) {
      if (e.name === 'UsernameExistsException') {
        try {
          await Auth.signIn(values.email, values.password);
        } catch (error: any) {
          if (error?.name === 'UserNotConfirmedException') {
            Auth.resendSignUp(values.email);
            changeView('verify', values.email, values.name, values.password);
            return;
          }
        }
      }
      setError(e?.message ?? null);
    }
  };

  return (
    <Box mt='10px'>
      <Flex onClick={() => changeView('initial')}>
        <Image src={backIcon} _hover={{ cursor: 'pointer' }} />
        <Text css={createAccountStyles} mr='36px' ml='36px'>
          {t('AUTHENTICATION.CREATE_NEW_ACCOUNT')}
        </Text>
      </Flex>
      <Flex mt='34px' justifyContent='space-between'>
        <Button
          css={facebookButtonStyles}
          leftIcon={<Image src={facebookIcon} h='29px' w='29px' />}
          fontWeight='normal'
          onClick={facebookSignIn}
        >
          <Text width='100%'>Facebook</Text>
        </Button>
        <Button
          css={facebookButtonStyles}
          leftIcon={<Image src={googleIcon} h='29px' w='29px' />}
          fontWeight='normal'
          onClick={googleSignIn}
        >
          <Text width='100%'>Google</Text>
        </Button>
      </Flex>
      <Box mt='22px'>
        <MidLineText text={t('AUTHENTICATION.OR')} />
      </Box>
      <Box mt='14px'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <Grid container spacing={1}>
                {error && (
                  <Grid item xs={12}>
                    <Alert status='error'>
                      <AlertIcon />
                      <AlertTitle>{error}</AlertTitle>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    name='name'
                    label={t('AUTHENTICATION.FULL_NAME')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='text'
                    fullWidth
                    component={Input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='email'
                    label={t('AUTHENTICATION.EMAIL_ADDRESS')}
                    icon={emailIcon}
                    type='authentication'
                    detailedType='email'
                    fullWidth
                    component={Input}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='password'
                    label={t('AUTHENTICATION.PASSWORD')}
                    icon={emailIcon}
                    type='authentication'
                    fullWidth
                    component={Input}
                    detailedType='password'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    c='9E9E9E'
                    fontSize='16px'
                    lineHeight='21px'
                    letterSpacing='0'
                    textAlign='start'
                    fontFamily='lato'
                  >
                    <Field name='save' fullWidth>
                      {({ form, field }: { form: any; field: any }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              classes={{ ...checkboxClasses }}
                              style={{ textAlign: 'start' }}
                              checked={field.value}
                              onChange={(e: any) => {
                                form.setFieldValue(
                                  field.name,
                                  e.target.checked
                                );
                              }}
                              name='save'
                              color='primary'
                            />
                          }
                          label={
                            <Box
                              c='#4E4E4E'
                              fontSize='13px'
                              fontWeight='bold'
                              letterSpacing='0'
                              lineHeight='16px'
                            >
                              {t('AUTHENTICATION.I_AGREE') + ' '}
                              <Link
                                css={linkStyles}
                                to={ROUTES.TERMS_OF_USE}
                                as={RouterLink}
                                onClick={onClose}
                              >
                                {t('DASHBOARD.TERMS_OF_USE')}
                              </Link>
                              {' ' + t('AUTHENTICATION.AND') + ' '}
                              <Link
                                css={linkStyles}
                                to={ROUTES.PRIVACY}
                                as={RouterLink}
                                onClick={onClose}
                              >
                                {t('DASHBOARD.PRIVACY_POLICY')}
                              </Link>
                            </Box>
                          }
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
              </Grid>
              <Button
                css={SignUpButtonStyles}
                disabled={isSubmitting || !isValid}
                type='submit'
              >
                {t('AUTHENTICATION.SIGN_UP')}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default SignUp;
