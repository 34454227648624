import { getHttpService } from '../httpService';

export type ReviewsType = 'user' | 'seller';
const getEndPoint = (): Record<ReviewsType, string> => ({
  seller: '/seller/ratings',
  user: '/ratings'
});

type GetReviewsAndRatingsParams = {
  limit: number;
  offset: number;
};

type GetReviewsAndRatingsResponse = {
  items: Array<{
    user: {
      id: number;
      name: string;
      profile_photo: string | null;
      is_verified?: boolean;
    };
    rate: number;
    comment: string;
    created: string;
  }>;
  total: number;
  limit: number;
  offset: number;
};

async function getReviewsAndRatings(
  reviewsType: ReviewsType,
  params: GetReviewsAndRatingsParams = {
    limit: 100,
    offset: 0
  }
): Promise<GetReviewsAndRatingsResponse> {
  const response = await getHttpService(
    'secrets'
  ).get<GetReviewsAndRatingsResponse>(getEndPoint()[reviewsType], {
    params
  });

  return response.data;
}

export { getReviewsAndRatings };
