import {Box} from "@chakra-ui/react";

const LineSeperator = () => {
    const lineStyles = {
        width: '100%',
        height: '1px',
        border: '1px solid #E9E9E9'
    }
    return (
        <Box css={lineStyles}>
        </Box>
    )
}

export default LineSeperator;