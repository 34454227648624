import sell_secret from '../assets/icons/sell_secret.svg';
import sell_secret_selected from '../assets/icons/sell_secret_selected.svg';
import discover from '../assets/icons/discover.svg';
import discover_selected from '../assets/icons/discover_selected.svg';
import favourites from '../assets/icons/favourites.svg';
import favourites_selected from '../assets/icons/favourites_selected.svg';

export const NAV_ITEMS = [
  {
    label: 'NAV.SELL_SECRET',
    icon: sell_secret,
    selectedIcon: sell_secret_selected,
    link: '/sell'
  },
  {
    label: 'NAV.DISCOVER',
    icon: discover,
    selectedIcon: discover_selected,
    link: '/discover'
  },
  {
    label: 'NAV.FAVORITES',
    icon: favourites,
    selectedIcon: favourites_selected,
    link: '/favorites'
  }
];

export const DASHBOARD_VIEWS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

export const SECRET_STATUS_FILTERS = ['ANY', 'APPROVED', 'REVEALED'];
export const SECRET_STATUS = {
  PENDING: 'PENDING',
  UNREVEALED: 'UNREVEALED',
  REVEALED: 'REVEALED',
  APPROVED: 'APPROVED'
};
export const SHARE_PRICES = [0.5, 1, 2, 5, 10];
// export const DATE_OPTIONS = [{id: 'DATE'}, {id: '24_HOURS', name: 'SELL.24_HOURS'}, {
//     id: '2_DAYS',
//     name: 'SELL.2_DAYS'
// }, {
//     id: '6_DAYS',
//     name: 'SELL.6_DAYS'
// }]
export const DATE_OPTIONS = [{ id: 'DATE' }];
export const SECRETS_PAGE_SIZE = 5;
export const NOTIFICATIONS_OFFSET = 6;

export const VIDEO_FORMATS = new Set([
  'mp4',
  'avi',
  'webm',
  'mov',
  'mpg',
  'mpeg',
  'ogg'
]);

export const AUDIO_FORMATS = new Set(['mp3', 'wav']);
export const IMAGE_FORMATS = new Set(['png', 'jpeg', 'jpg']);
export const VIDEO_AND_IMAGES_EXTENSIONS = [...VIDEO_FORMATS, ...IMAGE_FORMATS];

export const DOC_EXTENSIONS = [
  '.doc',
  '.docm',
  '.docx',
  '.pdf',
  '.dot',
  '.html',
  '.htm',
  '.odt',
  '.txt',
  '.xml',
  '.mp3',
  '.mp4',
  '.avi',
  '.webm'
];

export const EXPIRY_DATE_MONTHS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export const CREDIT_CARD_YEARS_INTERVAL = 20;
export const NOTIFICATIONS_NO_DIRECTS = [
  'NEW_SELLER',
  'PARTNER_SUBMITTED_FOR_REVIEW'
];
export const LANGUAGES = [
  { label: 'العربية', value: 'ARABIC' },
  { label: 'English', value: 'ENGLISH' }
];
