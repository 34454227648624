import { createIcon } from '@chakra-ui/icons';

export const WalletIcon = createIcon({
  displayName: 'WalletIcon',
  viewBox: '0 0 24 24',
  path: (
    <g id='main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-36.000000, -567.000000)'
        fill='currentColor'
        fillRule='nonzero'
      >
        <g id='side_menu_trending' transform='translate(0.000000, 67.000000)'>
          <g id='wallet-(1)' transform='translate(36.000000, 500.000000)'>
            <path
              d='M21.7495256,19.0973324 L18.0362065,19.0973324 C15.6962291,19.0973324 13.7924488,17.193552 13.7924488,14.8535747 C13.7924488,12.5135973 15.6962291,10.609817 18.0362065,10.609817 L21.7495256,10.609817 C22.0427438,10.609817 22.2800139,10.3725469 22.2800139,10.0793286 L22.2800139,8.48791327 C22.2800139,7.37551393 21.4171684,6.47084403 20.3269991,6.38311726 L17.2804334,1.06182216 C16.9981063,0.569676966 16.5422156,0.21792444 15.9967082,0.0718623497 C15.4537869,-0.0732051064 14.8860495,0.00298390379 14.4001208,0.285808252 L3.95630831,6.36600955 L2.12205291,6.36600955 C0.951815578,6.36600955 0.00014919519,7.3176262 0.00014919519,8.48791327 L0.00014919519,21.2192361 C0.00014919519,22.3894735 0.951765846,23.3411398 2.12205291,23.3411398 L20.1581102,23.3411398 C21.3283475,23.3411398 22.2800139,22.3895232 22.2800139,21.2192361 L22.2800139,19.6278208 C22.2800139,19.3346025 22.0427438,19.0973324 21.7495256,19.0973324 Z M17.9383344,4.34710024 L19.0941993,6.36600955 L14.4705906,6.36600955 L17.9383344,4.34710024 Z M6.06493365,6.36600955 L14.9341898,1.20271216 C15.174046,1.06231948 15.4542843,1.02502068 15.7221394,1.09653491 C15.9930778,1.16904377 16.2189593,1.3441492 16.359352,1.58917743 L16.3604461,1.59111697 L8.15918653,6.36600955 L6.06493365,6.36600955 Z'
              id='Shape'
            />
            <path
              d='M21.7495256,11.670744 L18.0362065,11.670744 C16.2810743,11.670744 14.8533758,13.0984425 14.8533758,14.8535747 C14.8533758,16.6087069 16.2810743,18.0364054 18.0362065,18.0364054 L21.7495256,18.0364054 C22.6270917,18.0364054 23.3409409,17.3225562 23.3409409,16.44499 L23.3409409,13.2621593 C23.3409409,12.3845932 22.6270917,11.670744 21.7495256,11.670744 Z M18.0362065,15.9145017 C17.4513613,15.9145017 16.9752795,15.4384198 16.9752795,14.8535747 C16.9752795,14.2687295 17.4513613,13.7926477 18.0362065,13.7926477 C18.6210516,13.7926477 19.0971335,14.2687295 19.0971335,14.8535747 C19.0971832,15.4384198 18.6211014,15.9145017 18.0362065,15.9145017 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </g>
  )
});
