import React, { FunctionComponent } from 'react';
import { Box, Button, Image, Link, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import facebookIcon from '../../assets/icons/facebook.svg';
import googleIcon from '../../assets/icons/google.svg';
import emailIcon from '../../assets/icons/email.svg';
import MidLineText from '../MidLineText';
import { CurrentView } from '../SigninContainer/types';
import { Auth } from 'aws-amplify';
import { usersApi } from '../../api';
import { Link as RouterLink } from 'react-router-dom';

type SigninGeneralProps = {
  changeView: (currentView: CurrentView) => void;
  onClose: () => void;
};

const facebookButtonStyles = {
  backgroundColor: '#FFFFFF',
  borderRadius: '25px',
  color: '#888888',
  fontSize: '17px',
  letterSpacing: '0',
  lineHeight: '21px',
  fontFamily: 'lato',
  justifyContent: 'flex-start',
  height: '51px',
  padding: '11px 29px 11px 29px'
};

const continueButtonStyles = {
  width: '100%',
  backgroundColor: '#FFFFFF',
  borderRadius: '25px',
  color: '#802081',
  fontSize: '17px',
  letterSpacing: '0',
  lineHeight: '21px',
  fontFamily: 'lato',
  height: '51px',
  padding: '11px 29px 11px 29px',
  border: '1px solid #802081',
  boxSizing: 'border-box',
  fontWeight: 'normal'
} as const;

const termsAndConditionStyles = {
  marginTop: '38px',
  fontFamily: 'lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '24px',
  textAlign: 'center',
  fontWeight: 'normal',
  width: '100%'
} as const;

const linkStyles = {
  color: '#802081'
};
const SigninGeneral: FunctionComponent<SigninGeneralProps> = ({
  changeView,
  onClose
}) => {
  const { t } = useTranslation();
  const googleSignIn = () => {
    Auth.federatedSignIn({
      provider: 'Google'
    } as any).then(async (cred) => {});
  };
  const facebookSignIn = () => {
    Auth.federatedSignIn({
      provider: 'Facebook'
    } as any).then((cred) => {
      return Auth.currentAuthenticatedUser();
    });
  };
  return (
    <Box>
      <Text
        color='#494949'
        fontFamily='Lato'
        fontSize='16px'
        letterSpacing='0'
        lineHeight='19px'
        mt='10px'
        textAlign='center'
      >
        {t('AUTHENTICATION.SIGN_IN')}
      </Text>
      <Stack mt='36px'>
        <Button
          css={facebookButtonStyles}
          leftIcon={<Image src={facebookIcon} h='29px' w='29px' />}
          fontWeight='normal'
          onClick={facebookSignIn}
        >
          <Text width='100%'>Facebook</Text>
        </Button>
        <Button
          css={facebookButtonStyles}
          leftIcon={<Image src={googleIcon} h='29px' w='29px' />}
          fontWeight='normal'
          onClick={googleSignIn}
        >
          <Text width='100%'>Google</Text>
        </Button>
        <Button
          css={facebookButtonStyles}
          leftIcon={<Image src={emailIcon} h='29px' w='29px' />}
          onClick={() => changeView('email-sign-in')}
          fontWeight='normal'
        >
          <Text width='100%'>Email</Text>
        </Button>
      </Stack>
      <Box mt='20px'>
        <MidLineText text={t('AUTHENTICATION.OR')} />
      </Box>
      <Stack mt='30px'>
        <Button css={continueButtonStyles} onClick={onClose}>
          {t('AUTHENTICATION.CONTINUE_AS_GUEST')}
        </Button>
        <Button
          css={continueButtonStyles}
          onClick={() => {
            changeView('signup');
          }}
        >
          {t('AUTHENTICATION.CREATE_NEW_ACCOUNT')}
        </Button>
      </Stack>
      <Box css={termsAndConditionStyles}>
        <Trans>
          When you signing in, you are agreeing to the{' '}
          <Link as={RouterLink} to='/terms-of-use' css={linkStyles} onClick={onClose}>Terms of Use</Link> &{' '}
          <Link as={RouterLink} to='privacy' css={linkStyles} onClick={onClose}>Privacy Policy</Link>
        </Trans>
      </Box>
    </Box>
  );
};

export default SigninGeneral;
