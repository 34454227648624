import {CurrentView} from "../SigninContainer/types";
import {FunctionComponent} from "react";
import {Box, Button, Image, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

type CheckEmailProps = { changeView: (currentView: CurrentView) => void }

const checkEmailStyles = {
    color: "#494949",
    fontFamily: "Lato",
    fontSize: "17px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "19px",
    marginTop: "25px",
    textAlign: "center"
} as const;

const justEmailedStyles = {
    fontFamily: "Lato",
    color: "#252525",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "18px",
    marginTop: "14px",
    textAlign: "center"
} as const;

const signInStyles = {
    marginTop: "25px",
    width: "100%",
    backgroundColor: "#802081",
    borderRadius: "23.5px",
    color: "#FFFFFF",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "19px",
    fontFamily: "lato",
    height: "48px",
    padding: "11px 29px 11px 29px",
    boxSizing: "border-box",
    fontWeight: "bold"
} as const;

const CheckEmail: FunctionComponent<CheckEmailProps> = ({changeView}) => {
    const {t} = useTranslation();
    return (
        <Box>
            <Image/>
            <Text css={checkEmailStyles}>{t('AUTHENTICATION.CHECK_IN_YOUR_EMAIL')}</Text>
            <Text css={justEmailedStyles}>{t('AUTHENTICATION.WE_JUST_EMAILED')}</Text>
            <Button css={signInStyles}
                    onClick={() => changeView('reset-password')}>{t('AUTHENTICATION.SET_NEW_PASSWORD')}</Button>
        </Box>
    )
}

export default CheckEmail;
