import React, {FunctionComponent} from 'react';
import {Box} from "@chakra-ui/react"; // importing FunctionComponent
import loginGuide from "../../assets/images/login_guide.svg";
import {Image, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

type SecretsvilleGuideProps = {}

const guideContainerStyles = {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    height: "520px",
    padding: "52px 70px 147px 70px"
}

const guideDescriptionStyles = {
    color: "#373737",
    fontSize: "18px",
    fontWeight: 700,
    letterSpacing: "0",
    lineHeight: "35px",
    marginTop: "17px"
}
const SecretsvilleGuide: FunctionComponent<SecretsvilleGuideProps> = ({}) => {
    //TODO: Add video
    const {t} = useTranslation();
    return (
        <Box css={guideContainerStyles}>
            <Image src={loginGuide} margin="auto"/>
            <Text css={guideDescriptionStyles}>
                {t('AUTHENTICATION.GUIDE_DESCRIPTION')}
            </Text>
        </Box>
    )
}

export default SecretsvilleGuide;
