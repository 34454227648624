import './App.css';
import { AuthProvider } from './utils/AmplifyContext';
import ExtendedApp from './ExtendedApp';

function App() {
  console.log('App - 0.0.09');
  return (
    <AuthProvider>
      <ExtendedApp />
    </AuthProvider>
  );
}

export default App;
