import { CurrentView } from '../SigninContainer/types';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Alert,
  AlertIcon,
  AlertTitle
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import backIcon from '../../assets/icons/back_black.svg';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import emailIcon from '../../assets/icons/email_icon.svg';
import Input from '../Input/Input';
import * as Yup from 'yup';
import { useAuth } from '../../utils/AmplifyContext';
import { usersApi } from '../../api';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useTimer } from 'react-timer-hook';

type VerifyPasswordProps = {
  email: string;
  changeView: (currentView: CurrentView) => void;
  onClose: () => void;
  name: string;
  password: string;
};

const forgotPasswordStyles = {
  color: '#494949',
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: 500,
  letterSpacing: '0',
  lineHeight: '19px'
} as const;

const enterEmailStyles = {
  color: '#252525',
  fontFamily: 'Lato',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '17px',
  marginTop: '13px',
  textAlign: 'center',
  paddingLeft: '27px',
  paddingRight: '67px'
} as const;

const resetPasswordButtonStyles = {
  marginTop: '42px',
  width: '100%',
  backgroundColor: '#802081',
  borderRadius: '23.5px',
  color: '#FFFFFF',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '19px',
  fontFamily: 'lato',
  height: '48px',
  padding: '11px 29px 11px 29px',
  boxSizing: 'border-box',
  fontWeight: 'bold'
} as const;

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Invalid code')
});
const VerifyEmail: FunctionComponent<VerifyPasswordProps> = ({
  email,
  changeView,
  onClose,
  name,
  password
}) => {
  const user = useAuth();
  const { t } = useTranslation();
  const { seconds, restart, pause, minutes, isRunning } = useTimer({
    expiryTimestamp: new Date(Date.now() + 1000 * 60)
  });
  const [error, setError] = useState('');
  const onSubmit = async (values: any, formikBag: any) => {
    const { setSubmitting } = formikBag;
    setSubmitting(true);
    setTimeout(async () => {
      try {
        //Ignore /me api error
        await user.verifyCode(email, values.code);
        try {
          await user.login(email, password);
        } catch (e) {}
      } catch (error: any) {
        setError(error.message);
      } finally {
        usersApi
          .post('users/', { name: name, Source: 'STANDARD' })
          .then(async (data) => {
            setSubmitting(false);
            await user.login(email, password);
            onClose();
          });
      }
    }, 2000);
  };
  return (
    <Box mt='10px'>
      <Flex onClick={() => changeView('initial')}>
        <Image src={backIcon} _hover={{ cursor: 'pointer' }} />
        <Text css={forgotPasswordStyles} mr='36px' ml='36px'>
          {t('AUTHENTICATION.VERIFY_ACCOUNT')}
        </Text>
      </Flex>
      <Text css={enterEmailStyles}>
        {t('AUTHENTICATION.ENTER_VERIFICATION_CODE')}
      </Text>
      <Box mt='36px'>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              {error && (
                <Alert status='error'>
                  <AlertIcon />
                  <AlertTitle>{error}</AlertTitle>
                </Alert>
              )}
              <Field
                name='code'
                label={t('AUTHENTICATION.VERIFICATION_CODE')}
                icon={emailIcon}
                type='authentication'
                detailedType='text'
                fullWidth
                component={Input}
              />

              {isRunning ? (
                <Button
                  variant={'link'}
                  colorScheme='blue'
                  size={'xs'}
                  disabled
                >
                  {t('AUTHENTICATION.RESEND_CODE')}
                  {minutes.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}
                  :
                  {seconds.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                  })}
                </Button>
              ) : (
                <Button
                  variant={'link'}
                  colorScheme='blue'
                  size={'xs'}
                  onClick={async () => {
                    try {
                      await Auth.resendSignUp(email);
                      restart(new Date(Date.now() + 1000 * 60));
                      toast.success('Please check your inbox', {
                        position: 'top-center',
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: false
                      });
                    } catch (err: any) {
                      toast.error(
                        err.message ?? 'Something when wrong, try again',
                        {
                          position: 'top-center',
                          autoClose: 5000,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          icon: false
                        }
                      );
                    }
                  }}
                >
                  {t('AUTHENTICATION.RESEND_VERIFICATION_EMAIL')}
                </Button>
              )}
              <Button
                css={resetPasswordButtonStyles}
                disabled={isSubmitting || !isValid}
                type='submit'
              >
                {t('AUTHENTICATION.VERIFY')}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
