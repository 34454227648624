import SearchBar from "material-ui-search-bar";
import {useState} from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        width: "213px",
        background: "#F3F3F3",
        border: "none",
        height: "35px",
        borderRadius: "17.5px",
        color: "#939393",
        fontFamily: "lato",
        fontSize: "12px",
        letterSpacing: "0.4px",
        lineHeight: "15px",
        boxShadow: "none"
    }
});


const Search = (props) => {
    const classes = useStyles();
    const [search, setSearch] = useState('');
    const searchFunction = () => {
        props.onSearch(search)
    }
    return (
        // <input
        //     style={BarStyling}
        //     key="random1"
        //     value={keyword}
        //     placeholder={"search"}
        //     onChange={(e) => setKeyword(e.target.value)}
        // />
        <SearchBar
            classes={{...classes}}
            value={props.value}
            onChange={(newValue) => setSearch(newValue)}
            onRequestSearch={() => searchFunction()}
        />
    );
}

export default Search;
