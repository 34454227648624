import { Box, useMediaQuery } from '@chakra-ui/react';
import { LayoutPositionEnum } from '../UserProfile/Layout';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING
} from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { secretsApi } from 'api';
import { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast
} from '@chakra-ui/react';
import { layoutCss, layoutMobileCss } from 'pages/UserProfile/Shared';
import { LayoutItem } from 'pages/UserProfile/Layout';
import PaymentForm from 'components/PaymentForm/PaymentForm';
import { CheckoutSummary } from 'pages/Checkout/components/CheckoutSummary';
const AddPaymentStyles = {
  overflow: 'auto',
  backgroundImage: 'linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 100%);'
};

const MobileCheckout = () => {
  const { t, i18n } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');
  const secretId = queryParams.get('secretId');
  const numberOfShares = parseInt(queryParams.get('numberOfShares') ?? '1');
  const layoutStyles = isMobile ? { ...layoutMobileCss } : { ...layoutCss };
  const [sharePrice, setSharePrice] = useState(0);
  const cardButtonRef = useRef<any>();
  const [secret, setSecret] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (secretId) {
      secretsApi.get(`v1/secrets/${secretId}`).then(
        (data) => {
          setSharePrice(data.data.share_price);
          setSecret(data.data);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [secretId]);
  const createPayPalOrder = async (data: any, actions: any) => {
    try {
      // await secretsApi.post(
      //   `v1/secrets/${secretId}/participate/validation`,
      //   {
      //     shares_number: numberOfShares
      //   },
      //   token
      //     ? {
      //         headers: { Authorization: `Bearer ${token}` }
      //       }
      //     : {}
      // );
      return actions.order.create({
        intent: 'AUTHORIZE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: `${numberOfShares * sharePrice}`
            }
          }
        ]
      });
    } catch (e: any) {
      window.location.replace('/mobile-checkout?status=failed');
      toast.error(e?.message ?? 'Something when wrong, try again', {
        position: 'top-center',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: false
      });
    }
  };

  const onApprove = (paymentType: string) => async (data: any, action: any) => {
    action.order
      ?.authorize()
      .then((res: any) => {
        return secretsApi.post(
          `v2/secrets/${secretId}/participate`,
          {
            payment_method_type: paymentType,
            order_id: res.id,
            payment_source: 'ANDROID'
          },
          token
            ? {
                headers: { Authorization: `Bearer ${token}` }
              }
            : {}
        );
      })
      .then(() => {
        window.location.replace(
          '/mobile-checkout?status=success&pmType=' + paymentType
        );
      })
      .catch((error: any) => {
        window.location.replace(
          '/mobile-checkout?status=failed&pmType=' + paymentType
        );
      });
  };
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <Box
      position='fixed'
      top={0}
      height={'100vh'}
      width='100vw'
      overflow='auto'
    >
      <Box
        m='auto'
        backgroundColor='#FFFFFF'
        h='100vh'
        borderRadius='15px'
        overflow={'auto'}
      >
        <AlertDialog
          motionPreset='slideInBottom'
          onClose={onClose}
          isOpen={isOpen}
          leastDestructiveRef={cancelRef.current}
          isCentered
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>
              {t('ADD_PAYMENT.SUCCESS_TITLE')}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {t('ADD_PAYMENT.SUCCESS_DESCRIPTION')}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('CHECKOUT.OK')}</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <CheckoutSummary
          numberOfShares={numberOfShares}
          sharePrice={sharePrice}
        />
        <LayoutItem section='main' {...layoutStyles}>
          {numberOfShares * sharePrice > 0 && (
            <PayPalScriptProvider
              key={i18n.language}
              options={{
                'client-id': process.env.REACT_APP_PAYPAL ?? '',
                currency: 'USD',
                intent: 'authorize',
                locale: i18n.language === 'ar' ? 'ar_JO' : 'en_US'
              }}
            >
              <PayPalButtons
                style={{ layout: 'horizontal' }}
                fundingSource={FUNDING.PAYPAL}
                createOrder={createPayPalOrder}
                onApprove={onApprove('PAYPAL')}
              />

              <PayPalButtons
                style={{ layout: 'horizontal' }}
                fundingSource={FUNDING.CARD}
                createOrder={createPayPalOrder}
                onApprove={onApprove('CARD')}
              />
            </PayPalScriptProvider>
          )}
        </LayoutItem>
      </Box>
    </Box>
  );
};

export default MobileCheckout;
