import {FunctionComponent} from "react";
import {Flex, Text} from "@chakra-ui/react";
import SecretsVilleDivider from "../SecretsVilleDivider";

type MidLineTextProps = { text: string };

const textStyles = {
    marginRight: "10px",
    marginLeft: "10px",
    fontFamily: "lato",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "19px",
    color: "#888888"
} as const;
const MidLineText: FunctionComponent<MidLineTextProps> = ({text}) => {
    return (
        <Flex alignItems="center">
            <SecretsVilleDivider/>
            <Text css={textStyles}>{text}</Text>
            <SecretsVilleDivider/>
        </Flex>
    )
}

export default MidLineText;
