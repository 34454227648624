import {CurrentView} from "../SigninContainer/types";
import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import {FunctionComponent} from "react";
import backIcon from "../../assets/icons/back_black.svg";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import emailIcon from "../../assets/icons/email_icon.svg";
import Input from "../Input/Input";
import * as Yup from "yup";
import {useAuth} from "../../utils/AmplifyContext";

type ForgotPasswordProps = { changeView: (currentView: CurrentView, email?: string) => void }


const forgotPasswordStyles = {
    color: "#494949",
    fontFamily: "Lato",
    fontSize: "17px",
    fontWeight: 500,
    letterSpacing: "0",
    lineHeight: "19px"
} as const;

const enterEmailStyles = {
    color: "#252525",
    fontFamily: "Lato",
    fontSize: "14px",
    letterSpacing: "0",
    lineHeight: "17px",
    marginTop: "13px",
    textAlign: "center",
    paddingLeft: "27px",
    paddingRight: "67px"
} as const;

const resetPasswordButtonStyles = {
    marginTop: "42px",
    width: "100%",
    backgroundColor: "#802081",
    borderRadius: "23.5px",
    color: "#FFFFFF",
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "19px",
    fontFamily: "lato",
    height: "48px",
    padding: "11px 29px 11px 29px",
    boxSizing: "border-box",
    fontWeight: "bold"
} as const;

const initialValues = {
    email: ""
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Invalid email')
});
const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({changeView}) => {
    const user = useAuth();
    const {t} = useTranslation();
    const onSubmit = async (values: any, formikBag: any) => {
        const {setSubmitting} = formikBag;
        setSubmitting(true);
        await user.passwordRecovery(values.email);
        setSubmitting(false);
        changeView('reset-password', values.email);
    };
    return (
        <Box mt="10px">
            <Flex onClick={() => changeView('initial')}>
                <Image src={backIcon} _hover={{cursor: 'pointer'}}/>
                <Text css={forgotPasswordStyles} mr="36px" ml="36px">{t('AUTHENTICATION.FORGOT_YOUR_PASSWORD')}</Text>
            </Flex>
            <Text css={enterEmailStyles}>{t('AUTHENTICATION.ENTER_YOUR_EMAIL_ADDRESS')}</Text>
            <Box mt="36px">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}>
                    {({isSubmitting, isValid}) => (
                        <Form>
                            <Field name="email" label={t('AUTHENTICATION.EMAIL_ADDRESS')} icon={emailIcon}
                                   type="authentication" detailedType="email" fullWidth component={Input}/>
                            <Button css={resetPasswordButtonStyles} disabled={isSubmitting || !isValid} type="submit">
                                {t('AUTHENTICATION.RESET_PASSWORD')}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default ForgotPassword
