import React, { forwardRef, useEffect, useImperativeHandle } from 'react'; // importing FunctionComponent
import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import SecretsvilleGuide from '../SecretsvilleGuide';
import SigninContainer from '../SigninContainer';
import closeIcon from '../../assets/icons/close.svg';
import { Grid } from '@material-ui/core';

const authenticationContainerStyles = {
  boxShadow: '0 6px 15px 0 rgba(181,181,181,0.5)',
  borderRadius: '0 0 10px 10px',
  background: 'linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 100%)',
  maxWidth: '85%'
};
const AuthenticationModal = forwardRef(({}, ref) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  useImperativeHandle(ref, () => ({
    openModal() {
      onOpen();
    }
  }));
  return (
    <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent css={authenticationContainerStyles}>
        <ModalBody>
          <Box fontFamily='lato'>
            <Flex justifyContent='flex-end'>
              <Image
                src={closeIcon}
                _hover={{ cursor: 'pointer' }}
                onClick={onClose}
              />
            </Flex>
            <Box mt='30px'>
              <Grid container>
                {!isMobile && (
                  <Grid item xs={7}>
                    <SecretsvilleGuide />
                  </Grid>
                )}
                <Grid item xs={12} sm={5}>
                  <SigninContainer onClose={onClose} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default AuthenticationModal;
