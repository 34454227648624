import { useField } from 'formik';
import { LayoutItem } from './Layout';
import { useHistory } from 'react-router';
import { ReactComponent as BackIcon } from 'assets/icons/back_black.svg';
import {
  Text,
  Input,
  Modal,
  HStack,
  Avatar,
  Divider,
  FormLabel,
  ModalBody,
  ModalProps,
  IconButton,
  InputGroup,
  FormControl,
  ModalHeader,
  AvatarProps,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  FormErrorMessage,
  Icon as ChakraIcon,
  InputProps as BaseInputProps,
  useMediaQuery
} from '@chakra-ui/react';
import ProfileImage from 'assets/icons/profile.png';
import { getRelativePath } from 'routes/routes';
import { useAuth } from '../../utils/AmplifyContext';

export function UserAvatar(props: AvatarProps) {
  return (
    <Avatar
      position='relative'
      _before={{
        content: '""',
        position: 'absolute',
        width: '100%',
        inset: '0px',
        zIndex: '0',
        height: '100%',
        transform: 'scale(1.04) translateY(-1px)',
        borderRadius: '100%',
        backgroundColor: '#802081'
      }}
      size='2xl'
      sx={{
        '& > img': {
          zIndex: 2,
          backgroundColor: 'white'
        }
      }}
      {...props}
      src={props.src ?? ProfileImage}
    />
  );
}

export const layoutCss = {
  mx: 20,
  my: 7
};

export const layoutMobileCss = {
  mx: 5,
  my: 1
};

export function GoBack({
  title,
  Icon
}: {
  title: string;
  Icon: React.ReactNode;
}) {
  const history = useHistory();
  const { user } = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const layoutStyles = isMobile ? { ...layoutMobileCss } : { ...layoutCss };
  const isRtl = user?.language === 'ARABIC';
  const onBackClick = () => {
    history.replace(getRelativePath('PROFILE'));
  };
  return (
    <LayoutItem section='header' {...layoutStyles}>
      <HStack
        color='#4E4E4E'
        spacing={2}
        alignItems='center'
        position='relative'
      >
        <IconButton
          position={isMobile ? 'relative' : 'absolute'}
          inset='0'
          top={isMobile ? '' : '-25%'}
          left={isMobile ? '0' : isRtl ? '' : '-2.5rem'}
          right={isMobile ? '' : isRtl ? '-2.5rem' : ''}
          bg='transparent'
          aria-label='go back to profile'
          onClick={onBackClick}
          icon={
            <ChakraIcon
              transform={isRtl ? 'scaleX(-1)' : ''}
              viewBox='0 0 19 14'
              w='20px'
              h='15px'
              sx={{ '& *': { fill: 'currentColor' } }}
            >
              <BackIcon />
            </ChakraIcon>
          }
        />
        {Icon}
        <Text fontSize={16} fontWeight='bold'>
          {title}
        </Text>
      </HStack>
    </LayoutItem>
  );
}

export function FormModal({
  title = 'Add New Bank Details',
  isOpen,
  onClose,
  children
}: ModalProps & { title: string }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='brand.500'>{title}</ModalHeader>
        <Divider />
        <ModalCloseButton />
        <ModalBody pb={5}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

export type InputProps<T> = BaseInputProps & {
  error?: string;
  label: string;
  name: keyof T;
  LeftIcon?: React.ReactNode;
  RightIcon?: React.ReactNode;
  disabled?: boolean;
};

export function FormInput<T>({
  error,
  id,
  label,
  name,
  isRequired,
  LeftIcon,
  RightIcon,
  ...rest
}: InputProps<T>) {
  const [field, meta] = useField({ name, id });

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(meta.error && meta.touched)}
    >
      <FormLabel color='#9E9E9E' htmlFor={id}>
        {label}
      </FormLabel>
      <InputGroup borderRadius='md'>
        {LeftIcon}
        <Input {...rest} {...field} id={id} />
        {RightIcon}
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
